import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { getUserBusinessesData } from "selectors/businesses";
import { useSelector } from "react-redux";
import IncludeExclude from "nlib/ui/IncludeExclude";
import IntegrationServices from "const/IntegrationServices";
import Modal, { ModalFooter } from "nlib/ui/Modal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";

const SelectedBusinessesWindow = ({ service, businessesList, onClose }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const userBusinessesData = useSelector(getUserBusinessesData);

  const [selectedItems, setSelectedItem] = useState([]);

  const serviceInfo = useMemo(() => IntegrationServices.getByValue(service), [service]);

  const Icon = useMemo(() => () => <img className={Css.logo} src={serviceInfo.logo} />, [serviceInfo]);

  const connectedBusinessIds = useMemo(() => {
    return businessesList.filter((businessData) => {
      return userBusinessesData.some(({
        extraData: {
          xeroOrganizationId,
          quickBooksRealmId,
          zohoOrganizationId
        } = {}
      }) => {
        return [xeroOrganizationId, quickBooksRealmId, zohoOrganizationId].includes(businessData.itemId);
      });
    }).map(({ itemId }) => itemId);
  }, [businessesList, userBusinessesData]);

  const options = useMemo(() => {
    return businessesList.map((businessData) => ({
      value: businessData.itemId,
      label: businessData.businessName
    }));
  }, [businessesList]);

  const handleConnectButtonClick = useCallback(() => {
    onClose(selectedItems);
  }, [selectedItems, onClose]);

  const handleCloseClick = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    setSelectedItem(connectedBusinessIds);
  }, [connectedBusinessIds]);

  return (
    <Modal
      className={Css.selectedBusinessesWindow}
      iconComponent={Icon}
      title={Utils.replaceTextVars(uiTexts.selectServiceBusinesses, { service: serviceInfo.label })}
      onClose={handleCloseClick}>
      {!!options.length && (
        <div className={Css.info}>
          <Icons.Info />
          <span>{messages.selectIntegrationBusinessesDescription}</span>
        </div>
      )}
      <div className={Css.content}>
        {options.length
          ? (
            <IncludeExclude
              className={Css.select}
              options={options}
              value={selectedItems}
              onChange={setSelectedItem} />
          )
          : (
            <div className={Css.noDataContent}>
              <div>
                <div><Icons.Briefcase /></div>
                <div>{uiTexts.noData}</div>
              </div>
            </div>
          )}
      </div>
      <ModalFooter>
        <Button
          large primary
          disabled={!selectedItems.length}
          onClick={handleConnectButtonClick}>
          {selectedItems.every((id) => connectedBusinessIds.includes(id)) ? uiTexts.reconnect : uiTexts.connect}
        </Button>
        <Button large onClick={handleCloseClick}>
          {uiTexts.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectedBusinessesWindow);
