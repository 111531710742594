
import { Redirect, useLocation } from "react-router-dom";
import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import { getSelectedBusinessId } from "selectors/businesses";
import { useSelector } from "react-redux";
import Countries from "const/Countries";
import Pages from "nlib/pages/Pages";
import React from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";

const RedirectToDefaultRoute = () => {
  const { pathname, search } = useLocation();

  const activeOrganization = useSelector(getActiveOrganization);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const businessUser = useSelector(checkIsBusinessUser);

  const currentRoute = Pages.getCurrentRouteInfo(pathname).route;

  const czCountry = activeOrganization.countryCode === Countries.CZ;

  const defaultRoute = businessUser ? (czCountry ? UiRoutes.DOCUMENTS : UiRoutes.TRANSACTIONS) : UiRoutes.DASHBOARD;

  if (!selectedBusinessId) return <Redirect to="/" />;

  const { editTask } = Utils.parseQueryString(search);

  if (editTask) {
    return <Redirect to={`/${selectedBusinessId}${UiRoutes.TASKS}${search || ""}`} />;
  }

  if (currentRoute) {
    if (Pages.getPageData(currentRoute, businessUser)) {
      return (
        <Redirect to={`/${selectedBusinessId}${currentRoute}${search || ""}`} />
      );
    }

    return (
      <Redirect to={`/${selectedBusinessId}${defaultRoute}`} />
    );
  }

  return (
    <Redirect to={`/${selectedBusinessId}${defaultRoute}${search || ""}`} />
  );
};

export default React.memo(RedirectToDefaultRoute);
