import Css from "nlib/ui/Table/style.module.scss";

import React from "react";
import classNames from "classnames";

const TableCell = ({ children, className, show = true, ...restProps }) => {
  if (!show) return null;

  return (
    <div {...restProps} data-table-cell className={classNames(Css.tableCell, className)}>
      {children}
    </div>
  );
};

export default React.memo(TableCell);
