import Css from "./style.module.scss";

import AwaitingButton from "nlib/common/AwaitingButton";
import React, { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";

const UploadFilesButton = (props) => {
  const {
    className,
    capture,
    accept,
    multiple,
    disabled,
    children,
    onChange,
    ...rest
  } = props;

  const inputRef = useRef();

  const acceptFilesString = useMemo(() => {
    return accept ? accept.join(",") : undefined;
  }, [accept]);

  const handleButtonClick = useCallback(() => {
    if (disabled) return;
    inputRef.current.value = null;
    inputRef.current.click();
  }, [disabled]);

  const handleInputFileChange = useCallback((event) => {
    if (disabled) return;

    const selectedFiles = [...event.target.files];

    onChange(selectedFiles, event);
  }, [disabled, onChange]);

  return (
    <AwaitingButton
      {...rest}
      disabled={disabled}
      className={classNames(Css.uploadFilesButton, className)}
      onClick={handleButtonClick}>
      <input
        type="file"
        ref={inputRef}
        multiple={multiple}
        className={Css.fileInput}
        capture={capture}
        accept={acceptFilesString}
        onChange={handleInputFileChange} />
      {children}
    </AwaitingButton>
  );
};

export default React.memo(UploadFilesButton);
