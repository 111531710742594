import FormContext from "contexts/FormContext";
import Input from "nlib/ui/Input";
import React, { useCallback, useContext } from "react";
import Utils from "utils/Utils";

const FormElement = ({ name, precision, required, element: Component = Input, disabled: disabledProp, ...restProps }) => {
  const { values, errors, disabled, onChange } = useContext(FormContext);

  const value = values[name];

  const error = errors[name];

  const handleChange = useCallback((newValue) => {
    return onChange({
      name,
      value: newValue && (precision ? Utils.toMoneyNumber(newValue, true, precision) : newValue)
    });
  }, [name, onChange, precision]);

  return (
    <Component
      value={value}
      error={error}
      onChange={handleChange}
      {...(required ? {
        invalid: required && !value && (value !== 0),
        valid: required && (value || value === 0)
      } : {})}
      {...restProps}
      disabled={disabled || disabledProp} />
  );
};

export default React.memo(FormElement);
