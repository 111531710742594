import Css from "./style.module.scss";

import React, { useCallback } from "react";
import classNames from "classnames";

const DatePickerYear = (props) => {
  const { year, selected, disabled, onSelect } = props;

  const handleClick = useCallback(() => {
    if (disabled || !onSelect) return;
    onSelect(year);
  }, [disabled, onSelect, year]);

  return (
    <div
      className={classNames(Css.datePickerYear, {
        [Css.selected]: selected
      })}
      onClick={handleClick}>
      {year}
    </div>
  );
};

export default React.memo(DatePickerYear);
