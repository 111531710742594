import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const Row = ({ className, children }) => {
  return (
    <div className={classNames(Css.row, className)}>
      {children}
    </div>
  );
};

export default React.memo(Row);
