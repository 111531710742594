import Css from "./style.module.scss";

import DropDownContext from "./DropDownContext";
import React, { useRef, useState } from "react";
import classNames from "classnames";

const DropDown = (props) => {
  const [openedState, setOpenedState] = useState(false);

  const innerRef = useRef();

  const {
    children,
    className,
    opened = openedState,
    containerRef = innerRef,
    disabled,
    readOnly,
    setOpened = setOpenedState,
    dev,
    ...restProps
  } = props;

  return (
    <DropDownContext.Provider value={{ opened, disabled, readOnly, containerRef, setOpened }}>
      <div
        {...restProps}
        ref={containerRef}
        className={classNames(Css.dropDown, className)}>
        {children}
      </div>
    </DropDownContext.Provider>
  );
};

export { default as DropDownToggle } from "./lib/DropDownToggle";
export { default as DropDownCaret } from "./lib/DropDownCaret";
export { default as DropDownContent } from "./lib/DropDownContent";
export { default as DropDownMenuItem } from "./lib/DropDownMenuItem";
export { default as DropDownMenuDivider } from "./lib/DropDownMenuDivider";

export default React.memo(DropDown);
