import Css from "./style.module.scss";

import { Checkbox, Select } from "nlib/ui";
import { getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import FormContext from "contexts/FormContext";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { useCallback, useContext, useMemo } from "react";
import UiActions from "actions/UiActions";
import UserRoles from "const/UserRoles";

const ADD_NEW_CLIENT_OPTION_VALUE = "+";

const SelectAskPerson = ({ value, disabled, businessId, onChange, ...restProps }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const { values: { rpaAutoAskClient }, onChange: onFormChange } = useContext(FormContext);

  const usersData = useSelector(getAllUsersData);

  const businessUserEmails = useMemo(() => {
    return usersData
      .filter(({ role, businessIds = [] }) => UserRoles.checkIsBusiness(role) && businessIds.includes(businessId))
      .map(({ email }) => email.toLowerCase());
  }, [businessId, usersData]);

  const noOneOption = useMemo(() => {
    return { value: "none", label: uiTexts.noOneWillBeAsked };
  }, [uiTexts.noOneWillBeAsked]);

  const groups = useMemo(() => {
    return [
      { value: "0" },
      { value: "1", label: uiTexts.users }
    ];
  }, [uiTexts]);

  const options = useMemo(() => {
    if (!rpaAutoAskClient) {
      return [noOneOption];
    }

    return [
      { value: ADD_NEW_CLIENT_OPTION_VALUE, label: `+ ${uiTexts.inviteUser}`, group: "0" },
      ...(businessUserEmails.map((email) => ({ value: email, label: email, group: "1" })))
    ];
  }, [rpaAutoAskClient, uiTexts.inviteUser, businessUserEmails, noOneOption]);

  const handleChange = useCallback(async(newValue) => {
    if (newValue === ADD_NEW_CLIENT_OPTION_VALUE) {
      const result = await dispatch(UiActions.showAskClientWindow({ noPhone: true }));

      if (!result) return;

      const { userEmail, invite } = result;

      if (userEmail) {
        if (invite) {
          await dispatch(OrganizationsActions.inviteUser({
            email: userEmail,
            role: UserRoles.BUSINESS_MANAGER.roleId,
            businessIds: [businessId]
          }));
        }
        await dispatch(OrganizationsActions.askBusinessUser([], userEmail, false, false, true));
        await dispatch(OrganizationsActions.fetchUsersList(true, true));
        onChange(result.userEmail);
      }
    } else {
      onChange(newValue);
    }
  }, [businessId, dispatch, onChange]);

  const handleAutoAskToggleChange = useCallback(() => {
    onFormChange({ name: "rpaAutoAskClient", value: !rpaAutoAskClient });
  }, [rpaAutoAskClient, onFormChange]);

  return (
    <div className={Css.selectRpaFrequency}>
      <Select
        options={options}
        groups={groups}
        value={rpaAutoAskClient ? value : noOneOption.value}
        invalid={rpaAutoAskClient && !value}
        className={Css.input}
        disabled={disabled || !rpaAutoAskClient}
        placeholder={uiTexts.selectPersonEmail}
        onChange={handleChange}
        {...restProps} />
      <Checkbox
        toggle
        data-tooltip={rpaAutoAskClient ? uiTexts.turnOff : uiTexts.turnOn}
        value={rpaAutoAskClient}
        onClick={handleAutoAskToggleChange} />
    </div>
  );
};

export default React.memo(SelectAskPerson);
