import Css from "./style.module.scss";

import React, { useCallback, useContext } from "react";
import TabsContext from "nlib/ui/Tabs/TabsContext";
import classNames from "classnames";

const Tab = ({ children, value, className, onClick, ...restProps }) => {
  const { current, disabled, onChange } = useContext(TabsContext);

  const active = current === value;

  const handleClick = useCallback((event) => {
    if (onChange) onChange(value);
    if (onClick) onClick(event);
  }, [value, onClick, onChange]);

  return (
    <div
      {...restProps}
      disabled={disabled}
      onClick={handleClick}
      className={classNames(Css.tab, active && Css.active, className)}>
      {children}
    </div>
  );
};

export default React.memo(Tab);
