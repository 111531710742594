import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const AutoTooltip = ({
  children,
  className,
  tooltipClassName,
  contentClassName,
  active = true,
  ...restProps
}) => (
  <div
    {...restProps}
    className={classNames(Css.autoTooltip, { [Css.active]: active }, className)}>
    <div className={classNames(Css.content, contentClassName)}>
      {children}
    </div>
    {active ? (
      <div className={classNames(Css.tooltip, tooltipClassName)}>
        {children}
      </div>
    ) : null}
  </div>
);

export default React.memo(AutoTooltip);
