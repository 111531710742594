import Button from "nlib/ui/Button";
import React, { useCallback, useEffect, useRef, useState } from "react";

const AwaitingButton = ({ onClick, disabled, loading: loadingFromProps, ...restProps }) => {
  const mountedRef = useRef(true);

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async(...args) => {
    setLoading(true);

    await onClick(...args);

    if (mountedRef.current) setLoading(false);
  }, [onClick]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Button
      {...restProps}
      disabled={disabled || loading}
      loading={loading || loadingFromProps}
      onClick={handleClick} />
  );
};

export default React.memo(AwaitingButton);
