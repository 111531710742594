import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import PostSignUp from "nlib/common/PostSignUp";
import React from "react";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";

const NoBusinessData = () => {
  const { errors, messages } = useSelector(getTextsData);

  const { billingId, subscriptionId } = useSelector(getActiveOrganization);

  const userData = useSelector(getUserData);

  const businessUser = UserRoles.checkIsBusiness(userData.role);

  if (businessUser) {
    const [accessDeniedErrorA, accessDeniedErrorB] = errors.accessDenied;

    return (
      <div className={Css.noBusinessData}>
        <div className={Css.errorContainer}>
          <Icons.Warning weight="thin" className={classNames(Css.icon, CommonCss.warningText)} />
          <div className={Css.title}>{accessDeniedErrorA}</div>
          <div className={Css.description}>{accessDeniedErrorB}</div>
        </div>
      </div>
    );
  }

  if ((billingId && !subscriptionId) || userData.holdUser) {
    return (
      <div className={Css.noBusinessData}>
        <PostSignUp className={Css.postSignUp} />
      </div>
    );
  }

  const { DEFAULT_APP_URL } = Utils.getProcessEnvVars();

  const [useDesktopVersionMessageA, useDesktopVersionMessageB] = messages.useDesktopVersion;

  return (
    <div className={Css.noBusinessData}>
      <div className={Css.errorContainer}>
        <Icons.Desktop weight="thin" className={Css.icon} />
        <div className={Css.title}>{useDesktopVersionMessageA}</div>
        <div className={Css.description}>
          {Utils.replaceTextVars(useDesktopVersionMessageB, { appDomain: DEFAULT_APP_URL })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoBusinessData);
