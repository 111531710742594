import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useCallback } from "react";
import classNames from "classnames";

const Item = ({ value, label, highlight, disabled, onRemove }) => {
  const handleRemoveClick = useCallback(() => {
    if (!disabled) onRemove(value);
  }, [value, disabled, onRemove]);

  return (
    <div
      title={label}
      className={classNames(Css.item, disabled && Css.disabled, highlight && Css.highlight)}>
      <div className={Css.content}>{label}</div>
      <div className={Css.remove} onClick={handleRemoveClick}>
        <Icons.X />
      </div>
    </div>
  );
};

export default React.memo(Item);

