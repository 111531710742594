import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const DatePickerContainer = ({ className, children }) => {
  return (
    <div className={classNames(Css.datePickerContainer, className)}>
      {children}
    </div>
  );
};

export default React.memo(DatePickerContainer);
