import Css from "./style.module.scss";

import Button from "nlib/ui/Button";
import React, { useCallback } from "react";
import classNames from "classnames";

const Switcher = (props) => {
  const {
    light,
    value,
    className,
    options = [],
    disabled,
    invalid,
    onChange,
    ...resProps
  } = props;

  const handleButtonClick = useCallback((event, buttonValue) => {
    if (!onChange || (value === buttonValue)) return;
    onChange(buttonValue, event);
  }, [value, onChange]);

  return (
    <div
      className={classNames(
        Css.switcher,
        light && Css.light,
        className
      )}>
      {options.filter(Boolean).map((option) => {
        const active = option.value === value;

        return (
          <Button
            {...resProps}
            disabled={disabled}
            light={light || active}
            primary={light && active}
            danger={invalid}
            outline={invalid}
            key={option.value}
            className={classNames(Css.button, active && Css.active)}
            value={option.value}
            onClick={handleButtonClick}>
            {option.label}
          </Button>
        );
      })}
    </div>
  );
};

export default React.memo(Switcher);
