import Css from "./style.module.scss";

import { Select } from "nlib/ui";
import { getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { useCallback, useMemo } from "react";
import UiActions from "actions/UiActions";
import UserRoles from "const/UserRoles";

const ADD_NEW_CLIENT_OPTION_VALUE = "+";

const SelectDefaultAccountant = ({ value, disabled, businessId, onChange, ...restProps }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const usersData = useSelector(getAllUsersData);

  const accountantUserEmails = useMemo(() => {
    return usersData.filter(({ role, businessIds = [] }) => {
      return !UserRoles.checkIsBusiness(role) && (!businessIds.length || businessIds.includes(businessId));
    }).map(({ email }) => email.toLowerCase());
  }, [businessId, usersData]);

  const groups = useMemo(() => {
    return [
      { value: "0", label: uiTexts.accountants }
    ];
  }, [uiTexts]);

  const options = useMemo(() => {
    return accountantUserEmails.map((email) => ({ value: email, label: email, group: "0" }));
  }, [accountantUserEmails]);

  const handleChange = useCallback(async(newValue) => {
    if (newValue === ADD_NEW_CLIENT_OPTION_VALUE) {
      const result = await dispatch(UiActions.showAskClientWindow({ noPhone: true }));

      if (!result) return;

      const { userEmail, invite } = result;

      if (userEmail) {
        if (invite) {
          await dispatch(OrganizationsActions.inviteUser({
            email: userEmail,
            role: UserRoles.BUSINESS_MANAGER.roleId,
            businessIds: [businessId]
          }));
        }
        await dispatch(OrganizationsActions.askBusinessUser([], userEmail, false, false, true));
        await dispatch(OrganizationsActions.fetchUsersList(true, true));
        onChange(result.userEmail);
      }
    } else {
      onChange(newValue);
    }
  }, [businessId, dispatch, onChange]);

  return (
    <div className={Css.selectRpaFrequency}>
      <Select
        options={options}
        groups={groups}
        value={value}
        className={Css.input}
        disabled={disabled}
        placeholder={uiTexts.selectAccountantEmail}
        onChange={handleChange}
        {...restProps} />
    </div>
  );
};

export default React.memo(SelectDefaultAccountant);
