import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Modal } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import SelectCategoryInput from "nlib/common/SelectCategoryInput";

const SelectCategoryWindow = ({ onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [{ category, tags }, setState] = useState({ category: {}, tags: [] });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCategoryInputChange = useCallback((value) => {
    setState({ category: value, tags: value.code ? [value.name] : [] });
  }, []);

  const handleConfirmClick = useCallback(() => {
    onClose({ category, tags });
  }, [category, tags, onClose]);

  const handleCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      dialogClassName={Css.selectCategoryWindow}
      title={uiTexts.selectCategory}
      iconComponent={Icons.SquaresFour}
      onClose={handleClose}>
      <SelectCategoryInput
        value={category}
        invalid={!category.code}
        valid={category.code}
        onChange={handleCategoryInputChange} />
      <ModalFooter>
        <Button
          primary large
          disabled={!category.code}
          onClick={handleConfirmClick}>
          {uiTexts.save}
        </Button>
        <Button large onClick={handleCancelClick}>
          {uiTexts.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectCategoryWindow);
