import Css from "nlib/ui/Table/style.module.scss";

import React from "react";
import classNames from "classnames";

const TableRow = ({ children, className, ...restProps }) => {
  return (
    <div {...restProps} data-table-row="" className={classNames(Css.tableRow, className)}>
      {children}
    </div>
  );
};

export default React.memo(TableRow);
