import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const THEMES = {
  primary: Css.primary,
  negative: Css.negative,
  attention: Css.attention,
  positive: Css.positive,
  warning: Css.warning,
  alternative: Css.alternative,
  dark: Css.dark,
  counter: Css.counter
};

const Badge = (props) => {
  const {
    className,
    value,
    children = value,
    primary,
    negative,
    attention,
    positive,
    warning,
    alternative,
    counter,
    theme,
    inverted,
    ...restProps
  } = props;

  return (
    <span
      {...restProps}
      className={classNames(
        Css.badge,
        primary && Css.primary,
        negative && Css.negative,
        attention && Css.attention,
        positive && Css.positive,
        warning && Css.warning,
        alternative && Css.alternative,
        counter && Css.counter,
        inverted && Css.inverted,
        THEMES[theme],
        className
      )}>
      {children}
    </span>
  );
};

export default React.memo(Badge);
