import { useCallback, useContext } from "react";

import ContactFormContext from "contexts/ContactFormContext";

const useContactFormContext = (name) => {
  const contextData = useContext(ContactFormContext);

  const { state, onInputChange } = contextData;

  const handleChange = useCallback((value) => {
    onInputChange(name, value);
  }, [name, onInputChange]);

  return {
    ...contextData,
    value: name ? state[name] : state,
    onChange: handleChange
  };
};

export default useContactFormContext;
