import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import ContactForm, { ContactFormNameInput, ContactFormSubTypeSelect } from "nlib/common/ContactForm";
import ContactsActions from "actions/ContactsActions";
import DataConstants from "const/DataConstants";
import DropDown from "nlib/ui/DropDown";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import FormRow from "nlib/common/FormRow";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";

const { CONTACT_SUB_TYPES } = DataConstants;

const { VENDOR, CUSTOMER } = CONTACT_SUB_TYPES;

const NewContactWidget = (props) => {
  const {
    name,
    currency,
    opened,
    children,
    probablyVendor,
    disabledSubTypeInput,
    setOpened,
    onAddContact
  } = props;

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const xeroBusiness = integrationService === IntegrationServices.XERO.value;

  const prefillData = useMemo(() => ({
    currency,
    name,
    subType: xeroBusiness ? "" : (probablyVendor ? VENDOR : CUSTOMER)
  }), [name, currency, xeroBusiness, probablyVendor]);

  const handleCancelClick = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const handleFormSubmit = useCallback(async({ state }) => {
    setOpened(false);

    const contact = await dispatch(ContactsActions.addNewContact(state));

    if (contact && onAddContact) {
      onAddContact(contact);
    }
  }, [dispatch, setOpened, onAddContact]);

  return (
    <DropDown
      opened={opened}
      setOpened={setOpened}>
      {children}
      <DropDownContent
        modal={Utils.checkIsTouchDevice()}
        className={Css.newContactWidgetDropDown}>
        <ContactForm
          compact
          className={Css.form}
          prefillData={prefillData}
          onSubmit={handleFormSubmit}>
          {({ onSubmit, state }) => (
            <>
              {!xeroBusiness && (
                <FormRow>
                  <ContactFormSubTypeSelect useSwitcher disabled={disabledSubTypeInput} />
                </FormRow>
              )}
              <FormRow>
                <ContactFormNameInput />
              </FormRow>
              <FormRow wrap>
                <Button
                  success
                  light
                  disabled={!(state.name && state.name.trim())}
                  className={Css.button}
                  onClick={onSubmit}>
                  <Icons.Check />
                  {uiTexts.save}
                </Button>
                <Button
                  light
                  className={Css.button}
                  onClick={handleCancelClick}>
                  {uiTexts.cancel}
                </Button>
              </FormRow>
            </>
          )}
        </ContactForm>
      </DropDownContent>
    </DropDown>
  );
};

export default React.memo(NewContactWidget);
