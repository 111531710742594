import * as Yup from "yup";
import { INPUT_FIELD_NAMES } from "nlib/common/ContactForm/constants";
import Input from "nlib/ui/Input";
import React from "react";
import useContactFormContext from "nlib/common/ContactForm/useContactFormContext";

const EMAIL_VALIDATION_SCHEMA = Yup.string().trim().email();

const ContactFormEmailInput = ({ invalid, ...restProps }) => {
  const { value, disabled, readOnly, onChange } = useContactFormContext(INPUT_FIELD_NAMES.EMAIL);

  return (
    <Input
      placeholder="example@email.com"
      {...restProps}
      invalid={!EMAIL_VALIDATION_SCHEMA.isValidSync(value) || invalid}
      disabled={disabled}
      readOnly={readOnly}
      value={value || ""}
      onChange={onChange} />
  );
};

export default React.memo(ContactFormEmailInput);
