import { Redirect, useLocation } from "react-router-dom";
import { getAuthErrorDescription } from "selectors/user";
import { getEnvVars } from "selectors/envVars";
import { useSelector } from "react-redux";
import React from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";

const LoginRedirect = () => {
  const { search, pathname } = useLocation();

  const envVars = useSelector(getEnvVars);

  const userAuthError = useSelector(getAuthErrorDescription);

  const query = pathname === UiRoutes.MAIN || userAuthError
    ? (userAuthError ? Utils.objectToQueryString(envVars) : null)
    : Utils.objectToQueryString({ returnTo: btoa(pathname + search) });

  const redirectUrl = `${UiRoutes.LOGIN}${query ? `?${query}` : ""}`;

  return <Redirect to={redirectUrl} />;
};

export default React.memo(LoginRedirect);
