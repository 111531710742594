import Css from "./style.module.scss";

import React, { useMemo } from "react";
import TabsContext from "./TabsContext";
import classNames from "classnames";

const Tabs = ({ current, disabled, className, children, onChange }) => {
  const providedContext = useMemo(() => ({
    current, disabled, onChange
  }), [current, disabled, onChange]);

  return (
    <TabsContext.Provider value={providedContext}>
      <div className={classNames(Css.tabs, className)}>
        {children}
      </div>
    </TabsContext.Provider>
  );
};

export default React.memo(Tabs);

export { default as Tab } from "./lib/Tab";
