import Css from "./style.module.scss";

import googleDrive from "assets/googleDrive.svg";

import { getLanguage, getTextsData } from "selectors/texts";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import GoogleActions from "actions/GoogleActions";
import React, { useCallback, useEffect, useState } from "react";
import Utils from "utils/Utils";

const SERVICE_NAME = "Google Drive";

const FULL_ACCESS_SCOPE = "https://www.googleapis.com/auth/drive";

const READ_ONLY_SCOPE = "https://www.googleapis.com/auth/drive.readonly";

const GOOGLE_DOC_MIME_TYPE = "application/vnd.google-apps.document";

const GOOGLE_SHEET_MIME_TYPE = "application/vnd.google-apps.spreadsheet";

const FILE_DROP_MIME_TYPES = [
  ...(Object.values(Constants.DOCUMENT_FILE_TYPES).map(({ mimeType }) => mimeType)),
  GOOGLE_DOC_MIME_TYPE,
  GOOGLE_SHEET_MIME_TYPE
].join();

const loadGoogleApi = (name) => {
  return new Promise((resolve) => {
    window.gapi.load(name, { callback: resolve });
  });
};

const GoogleDrivePicker = (props) => {
  const {
    offlineAccess = false,
    onlySignIn = false,
    onSignIn,
    onlyFolders,
    onlyOneSelection,
    onFilesPicked
  } = props;

  const dispatch = useDispatch();

  const language = useSelector(getLanguage);

  const { uiTexts, errors } = useSelector(getTextsData);

  const [apiLoaded, setApiLoaded] = useState(false);

  const [authToken, setAuthToken] = useState(null);

  const createPicker = useCallback((argsAuthToken = authToken) => {
    if (!apiLoaded) return;

    const { GOOGLE_APP_ID, GDRIVE_PICKER_API_KEY } = Utils.getProcessEnvVars();

    const { google: { picker: { DocsView, ViewId, Feature, Action, PickerBuilder } }, location } = window;

    const defaultView = new DocsView(onlyFolders ? ViewId.FOLDERS : null)
      .setLabel(uiTexts.myDrive)
      .setParent("root")
      .setOwnedByMe(true)
      .setIncludeFolders(true);

    const sharedWithMeView = new DocsView(onlyFolders ? ViewId.FOLDERS : null)
      .setLabel(uiTexts.sharedWithMe)
      .setOwnedByMe(false)
      .setIncludeFolders(true);

    const sharedDrivesView = new DocsView(onlyFolders ? ViewId.FOLDERS : null)
      .setLabel(uiTexts.sharedDrives)
      .setEnableDrives(true)
      .setIncludeFolders(true);

    if (onlyFolders) {
      defaultView.setSelectFolderEnabled(true);
      sharedWithMeView.setSelectFolderEnabled(true);
      sharedDrivesView.setSelectFolderEnabled(true);
    }

    const picker = new PickerBuilder()
      .setAppId(GOOGLE_APP_ID)
      .setDeveloperKey(GDRIVE_PICKER_API_KEY)
      .setOAuthToken(argsAuthToken)
      .setLocale(language)
      .setOrigin(location.origin)
      .addView(defaultView)
      .addView(sharedWithMeView)
      .addView(sharedDrivesView)
      .setCallback(({ action, docs }) => {
        if (action === Action.PICKED) onFilesPicked(docs, argsAuthToken);
      });

    if (onlyFolders) picker.setTitle(uiTexts.selectFolder);
    else {
      picker
        .setTitle(uiTexts.selectDocuments)
        .setSelectableMimeTypes(FILE_DROP_MIME_TYPES);
    }

    if (!onlyOneSelection) picker.enableFeature(Feature.MULTISELECT_ENABLED);
    picker
      .build()
      .setVisible(true);
  }, [apiLoaded, authToken, language, onFilesPicked, onlyFolders, onlyOneSelection, uiTexts]);

  const handleGoogleAuthResult = useCallback((authResult) => {
    const requiredScope = offlineAccess ? FULL_ACCESS_SCOPE : READ_ONLY_SCOPE;

    if (authResult && authResult.scope && authResult.scope.split(" ").includes(requiredScope) && !authResult.error) {
      setAuthToken(authResult.access_token);
      if (onSignIn) onSignIn(authResult);
      if (!onlySignIn) createPicker(authResult.access_token);
    } else if (!authResult || authResult.error !== "popup_closed_by_user") {
      toast.error(errors.unknown);
    }
  }, [createPicker, errors.unknown, offlineAccess, onSignIn, onlySignIn]);

  const handleClick = useCallback(async() => {
    const { GDRIVE_CLIENT_ID } = Utils.getProcessEnvVars();

    if (authToken) {
      createPicker();
    } else {
      try {
        if (offlineAccess) {
          const authInstance = window.gapi.auth2.init({
            client_id: GDRIVE_CLIENT_ID,
            scope: FULL_ACCESS_SCOPE
          });

          const { code } = await authInstance.grantOfflineAccess();

          const authResult = await dispatch(GoogleActions.exchangeCode(code));

          handleGoogleAuthResult(authResult);
        } else {
          window.gapi.auth2.authorize(
            {
              client_id: GDRIVE_CLIENT_ID,
              scope: READ_ONLY_SCOPE,
              immediate: false
            },
            handleGoogleAuthResult
          );
        }
      } catch (error) {
        handleGoogleAuthResult(error);
      }
    }
  }, [authToken, createPicker, dispatch, handleGoogleAuthResult, offlineAccess]);

  useEffect(() => {
    Promise.all([
      loadGoogleApi("auth"),
      loadGoogleApi("picker")
    ]).then(() => {
      setApiLoaded(true);
    });
  }, []);

  return (
    <Button large className={Css.googleDrivePicker} disabled={!apiLoaded} onClick={handleClick}>
      <img src={googleDrive} alt={SERVICE_NAME} title={SERVICE_NAME} />
      <span>{SERVICE_NAME}</span>
    </Button>
  );
};

export default React.memo(GoogleDrivePicker);
