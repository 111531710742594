import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Form, { FormElement } from "nlib/common/Form";
import FormLabel from "nlib/common/FormLabel";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useMemo } from "react";
import UserActions from "actions/UserActions";
import Utils from "utils/Utils";

const KEYS = ["email", "firstName", "lastName"];

const UserSettingsWindow = ({ onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const initialValues = useMemo(() => Utils.getProps(userData, KEYS), [userData]);

  const handleFormSubmit = useCallback(({ values }) => {
    onClose();

    dispatch(UserActions.editUser({
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim()
    }));
  }, [dispatch, onClose]);

  return (
    <Modal
      size="sm"
      title={uiTexts.editUserProfile}
      iconComponent={Icons.User}
      className={Css.userSettingsWindow}
      onClose={onClose}>
      <Form initialValues={initialValues} onSubmit={handleFormSubmit}>
        {({ values: { firstName = "", lastName = "" }, hasChanges, onSubmit }) => {
          const firstNameValid = !!firstName.trim();

          const lastNameValid = !!lastName.trim();

          const formDataValid = firstNameValid && lastNameValid;

          return (
            <>
              {(!userData.firstName || !userData.lastName) && (
                <div className={Css.warning}>
                  <Icons.Warning /> <span>{messages.fillOutFullName}</span>
                </div>
              )}
              <FormLabel>{uiTexts.email}</FormLabel>
              <FormElement className={Css.input} readOnly name="email" />
              <FormLabel>{uiTexts.firstName}</FormLabel>
              <FormElement
                className={Css.input}
                placeholder={uiTexts.enterFirstName}
                name="firstName"
                invalid={!firstNameValid} />
              <FormLabel>{uiTexts.lastName}</FormLabel>
              <FormElement
                className={Css.input}
                placeholder={uiTexts.enterLastName}
                name="lastName"
                invalid={!lastNameValid} />
              <div className={Css.buttons}>
                <Button
                  large primary
                  disabled={!hasChanges || !formDataValid}
                  onClick={onSubmit}>
                  {uiTexts.save}
                </Button>
                <Button large onClick={onClose}>{uiTexts.cancel}</Button>
              </div>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default React.memo(UserSettingsWindow);
