import Css from "./style.module.scss";

import { RadioGroupContext } from "nlib/ui/RadioGroup";
import React, { useCallback, useContext } from "react";
import classNames from "classnames";

const Radio = (props) => {
  const {
    name: contextName,
    checked: checkedValue,
    onChange: onChangeFromContext
  } = useContext(RadioGroupContext) || {};

  const {
    className,
    name = contextName,
    value,
    checked = value === checkedValue,
    onChange = onChangeFromContext,
    children,
    ...restProps
  } = props;

  const handleChange = useCallback((event) => {
    if (onChange) onChange(value, event);
  }, [onChange, value]);

  return (
    <label className={classNames(Css.radio, className)} {...restProps}>
      <input
        className={Css.input}
        name={name}
        value={value}
        checked={checked}
        type="radio"
        onChange={handleChange} />
      <div className={Css.label}>{children}</div>
    </label>
  );
};

export default React.memo(Radio);
