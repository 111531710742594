import Css from "./style.module.scss";

import { MONTH_NAMES } from "nlib/ui/DatePicker/constants";
import DatePickerContainer from "nlib/ui/DatePicker/lib/DatePickerContainer";
import DatePickerContext from "nlib/ui/DatePicker/lib/DatePickerContext";
import DatePickerHeader from "nlib/ui/DatePicker/lib/DatePickerHeader";
import DatePickerMonth from "nlib/ui/DatePicker/lib/DatePickerMonth";
import React, { useCallback, useContext } from "react";

const DatePickerMonths = ({ translations }) => {
  const {
    startDateObject,
    currentYear,
    setCurrentYear,
    setCurrentMonth,
    setYearsView,
    setDaysView
  } = useContext(DatePickerContext);

  const monthNamesTranslation = (translations && translations.months) || MONTH_NAMES;

  const [selectedYear, selectedMonth] = startDateObject
    ? [startDateObject.getFullYear(), startDateObject.getMonth()] : [];

  const handlePrevClick = useCallback(() => {
    setCurrentYear((prev) => prev - 1);
  }, [setCurrentYear]);

  const handleNextClick = useCallback(() => {
    setCurrentYear((prev) => prev + 1);
  }, [setCurrentYear]);

  const handleSelect = useCallback((month) => {
    setCurrentMonth(month);
    setDaysView();
  }, [setCurrentMonth, setDaysView]);

  return (
    <DatePickerContainer className={Css.datePickerMonths}>
      <DatePickerHeader
        label={currentYear}
        onLabelClick={setYearsView}
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick} />
      <div className={Css.months}>
        {MONTH_NAMES.map((name, index) => (
          <DatePickerMonth
            key={name}
            month={index}
            name={monthNamesTranslation[index]}
            selected={currentYear === selectedYear && index === selectedMonth}
            onSelect={handleSelect} />
        ))}
      </div>
    </DatePickerContainer>
  );
};

export default React.memo(DatePickerMonths);
