import DataConstants from "./DataConstants";

const { DOCUMENT_PAYMENT_TYPES: { BUY, SELL }, TRANSACTION_TYPES: { WITHDRAW, DEPOSIT } } = DataConstants;

export default class IntegrationServicesCategories {
  static GROUPS = {
    quickBooks: {
      asset: { langId: "assets" },
      equity: { langId: "equity" },
      expense: { langId: "expenses" },
      liability: { langId: "liability" },
      other: { langId: "other" },
      revenue: { langId: "revenue" }
    },
    quickBooksDesktop: {
      asset: { langId: "assets" },
      costofgoodssold: { langId: "expenses" },
      equity: { langId: "equity" },
      expense: { langId: "expenses" },
      fixedasset: { langId: "assets" },
      income: { langId: "income" },
      liability: { langId: "liability" },
      other: { langId: "other" },
      othercurrentasset: { langId: "assets" },
      othercurrentliability: { langId: "liability" },
      otherexpense: { langId: "expenses" },
      revenue: { langId: "revenue" }
    },
    xero: {
      asset: { langId: "assets" },
      equity: { langId: "equity" },
      expense: { langId: "expenses" },
      liability: { langId: "liability" },
      revenue: { langId: "revenue" }
    },
    zoho: {
      cash: { langId: "assets", excludeFor: [BUY, SELL, WITHDRAW] },
      cost_of_goods_sold: { langId: "expenses", excludeFor: [DEPOSIT] },
      equity: { langId: "equity", excludeFor: [BUY, SELL, WITHDRAW] },
      expense: { langId: "expenses", excludeFor: [DEPOSIT] },
      fixed_asset: { langId: "assets" },
      income: { langId: "income", excludeFor: [WITHDRAW] },
      long_term_liability: { langId: "liability" },
      other_asset: { langId: "assets", excludeFor: [BUY, SELL] },
      other_current_asset: { langId: "assets" },
      other_current_liability: { langId: "liability" },
      other_expense: { langId: "expenses", excludeFor: [BUY, DEPOSIT, WITHDRAW] },
      other_income: { langId: "income", excludeFor: [WITHDRAW] },
      other_liability: { langId: "liability" },
      stock: { langId: "assets", excludeFor: [SELL, WITHDRAW] }
    }
  };

  static getGroupLangId(service, group) {
    const groups = IntegrationServicesCategories.GROUPS[service] || {};

    return (groups[group] || {}).langId || "other";
  }

  static checkIsAvailable(service, group, entityPaymentType) {
    const groups = IntegrationServicesCategories.GROUPS[service] || {};

    return !((groups[group] || {}).excludeFor || []).includes(entityPaymentType);
  }
}
