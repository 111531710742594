import Css from "./style.module.scss";

import { YEARS_PER_PAGE } from "nlib/ui/DatePicker/constants";
import DatePickerContainer from "nlib/ui/DatePicker/lib/DatePickerContainer";
import DatePickerContext from "nlib/ui/DatePicker/lib/DatePickerContext";
import DatePickerHeader from "nlib/ui/DatePicker/lib/DatePickerHeader";
import DatePickerYear from "nlib/ui/DatePicker/lib/DatePickerYear";
import React, { useCallback, useContext, useMemo } from "react";

const DatePickerYears = () => {
  const { startDateObject, currentYear, setCurrentYear, setMonthsView } = useContext(DatePickerContext);

  const startYear = currentYear - (currentYear % YEARS_PER_PAGE);

  const years = useMemo(() => {
    const selectedYear = startDateObject && startDateObject.getFullYear();

    const result = [];

    // eslint-disable-next-line no-loops/no-loops
    for (let iterator = 0; iterator < YEARS_PER_PAGE; iterator++) {
      const current = startYear + iterator;

      result.push({
        year: current,
        selected: current === selectedYear
      });
    }

    return result;
  }, [startDateObject, startYear]);

  const onPrevClick = useCallback(() => {
    setCurrentYear((prev) => prev - YEARS_PER_PAGE);
  }, [setCurrentYear]);

  const onNextClick = useCallback(() => {
    setCurrentYear((prev) => prev + YEARS_PER_PAGE);
  }, [setCurrentYear]);

  const onSelect = useCallback((year) => {
    setCurrentYear(year);
    setMonthsView();
  }, [setCurrentYear, setMonthsView]);

  return (
    <DatePickerContainer className={Css.datePickerYears}>
      <DatePickerHeader
        label={`${startYear} - ${startYear + YEARS_PER_PAGE - 1}`}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick} />
      <div className={Css.years}>
        {years.map(({ year, selected }) => (
          <DatePickerYear
            key={year}
            year={year}
            selected={selected}
            onSelect={onSelect} />
        ))}
      </div>
    </DatePickerContainer>
  );
};

export default React.memo(DatePickerYears);
