import { INPUT_FIELD_NAMES } from "nlib/common/ContactForm/constants";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Countries from "const/Countries";
import Input from "nlib/ui/Input";
import React from "react";
import SelectUsState from "./lib/SelectUsState";
import useContactFormContext from "nlib/common/ContactForm/useContactFormContext";

const ContactFormSelectState = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const { value, state, disabled, readOnly, onChange } = useContactFormContext(INPUT_FIELD_NAMES.STATE);

  const Component = state.countryCode === Countries.US ? SelectUsState : Input;

  return (
    <Component
      placeholder={state.countryCode === Countries.US ? uiTexts.state : uiTexts.enterStateName}
      {...props}
      disabled={disabled}
      readOnly={readOnly}
      value={value || ""}
      onChange={onChange} />
  );
};

export default React.memo(ContactFormSelectState);
