import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const FormLabel = ({ className, ...restProps }) => {
  return (
    <div className={classNames(Css.formLabel, className)} {...restProps} />
  );
};

export default React.memo(FormLabel);
