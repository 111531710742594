import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";
import classNames from "classnames";

const {
  TABLE_PAGE_SIZE,
  TABLE_PAGE_SIZE_MEDIUM,
  TABLE_PAGE_SIZE_MAXIMUM
} = Constants;

const PAGE_SIZE = [
  TABLE_PAGE_SIZE,
  TABLE_PAGE_SIZE_MEDIUM,
  TABLE_PAGE_SIZE_MAXIMUM
];

const SelectPageSize = ({ disabled, className, pageSize = TABLE_PAGE_SIZE, onChange }) => {
  const { uiTexts } = useSelector(getTextsData);

  const pageSizeOptions = useMemo(() => {
    return PAGE_SIZE.map((size) => ({ value: size, label: `${size} ${uiTexts.rows.toLowerCase()}` }));
  }, [uiTexts]);

  return (
    <div className={classNames(Css.pageSize, className)} disabled={disabled}>
      <span>{`${uiTexts.pageSize}: `}</span>
      <Select
        className={Css.select}
        value={pageSize}
        options={pageSizeOptions}
        onChange={onChange} />
    </div>
  );
};

export default React.memo(SelectPageSize);
