import Css from "./style.module.scss";

import React from "react";
import Row from "nlib/ui/Row";
import classNames from "classnames";

const FormRow = ({ wrap, className, ...restProps }) => {
  return (
    <Row className={classNames(Css.formRow, wrap && Css.wrap, className)} {...restProps} />
  );
};

export default React.memo(FormRow);
