import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import RpaTransactionTypes from "const/RpaTransactionTypes";
import Select from "nlib/ui/Select";

const { BOTH, INCOME, EXPENSES } = RpaTransactionTypes;

const SelectRpaTransactionTypes = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => {
    return [
      { value: BOTH, label: uiTexts.incomeAndExpenses },
      { value: INCOME, label: uiTexts.incomeOnly },
      { value: EXPENSES, label: uiTexts.expensesOnly }
    ];
  }, [uiTexts]);

  return (
    <Select options={options} {...props} />
  );
};

export default React.memo(SelectRpaTransactionTypes);
