export const INPUT_FIELD_NAMES = {
  ID: "id",
  TYPE: "type",
  PREFILL_ID_NUMBER: "prefillIdNumber",
  SUB_TYPE: "subType",
  NAME: "name",
  ID_NUMBER: "idNumber",
  VAT_ID: "vatId",
  COUNTRY_CODE: "countryCode",
  ZIP_CODE: "zipCode",
  STATE: "state",
  CITY: "city",
  STREET: "street",
  PHONE: "phone",
  VAT_PAYER: "vatPayer",
  CURRENCY: "currency",
  EMAIL: "email"
};

export const INPUT_FIELD_NAMES_ARRAY = Object.values(INPUT_FIELD_NAMES);
