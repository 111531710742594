import Css from "./style.module.scss";

import { useLocation } from "react-router-dom";
import Pages from "nlib/pages/Pages";
import React, { useMemo } from "react";
import classNames from "classnames";

const NoDataContent = ({ icon: propsIconComponent, title, description, children, className, ...restProps }) => {
  const { pathname } = useLocation();

  const { route } = useMemo(() => {
    return Pages.getCurrentRouteInfo(pathname);
  }, [pathname]);

  const iconComponent = useMemo(() => {
    return (Pages.getPageData(route) || {}).iconComponent;
  }, [route]);

  const IconComponent = propsIconComponent || iconComponent;

  return (
    <div className={classNames(Css.noDataContent, className)} {...restProps}>
      <div className={Css.container}>
        {!!IconComponent && <div className={Css.icon}><IconComponent /></div>}
        {!!title && <div className={Css.title}>{title}</div>}
        {!!description && <div className={Css.description}>{description}</div>}
        {!!children && <div className={Css.content}>{children}</div>}
      </div>
    </div>
  );
};

export default React.memo(NoDataContent);
