import { INPUT_FIELD_NAMES } from "nlib/common/ContactForm/constants";
import { getActiveOrganization } from "selectors/organizations";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";
import Switcher from "nlib/ui/Switcher";
import useContactFormContext from "nlib/common/ContactForm/useContactFormContext";

const { CONTACT_TYPES, CONTACT_SUB_TYPES } = DataConstants;

const ContactFormTypeSelect = ({ useSwitcher, disabled: disabledProp, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!(useSelector(getCurrentQuickBooksRealmId));

  const { countryCode } = useSelector(getActiveOrganization);

  const czCountry = countryCode === Countries.CZ;

  const { value, vendorId, disabled, readOnly, onChange } = useContactFormContext(
    czCountry ? INPUT_FIELD_NAMES.TYPE : INPUT_FIELD_NAMES.SUB_TYPE
  );

  const options = useMemo(() => {
    return Object.values(czCountry ? CONTACT_TYPES : CONTACT_SUB_TYPES)
      .map((type) => ({
        value: type,
        label: uiTexts[type]
      }));
  }, [czCountry, uiTexts]);

  const Element = useSwitcher ? Switcher : Select;

  return (
    <Element
      placeholder={uiTexts.selectType}
      {...restProps}
      invalid={quickBooksBusiness && !value}
      disabled={disabled || disabledProp || vendorId}
      readOnly={readOnly}
      value={value || ""}
      options={options}
      onChange={onChange} />
  );
};

export default React.memo(ContactFormTypeSelect);
