import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";
import classNames from "classnames";

const ACTIONS = {
  DOWNLOAD: "download",
  EDIT: "edit",
  SETTINGS: "settings",
  PREVIEW: "preview",
  DELETE: "delete",
  REVOKE: "revoke"
};

const ACTION_ICONS = {
  [ACTIONS.DOWNLOAD]: Icons.DownloadSimple,
  [ACTIONS.EDIT]: Icons.PencilSimpleLine,
  [ACTIONS.PREVIEW]: Icons.Eye,
  [ACTIONS.SETTINGS]: Icons.Gear,
  [ACTIONS.DELETE]: Icons.Trash,
  [ACTIONS.REVOKE]: Icons.UserCircleMinus
};

const ACTION_THEMES = {
  [ACTIONS.DOWNLOAD]: "success",
  [ACTIONS.EDIT]: "primary",
  [ACTIONS.SETTINGS]: "primary",
  [ACTIONS.PREVIEW]: "primary",
  [ACTIONS.DELETE]: "negative",
  [ACTIONS.REVOKE]: "negative"
};

const ACTION_TEXT_KEYS = {
  [ACTIONS.DOWNLOAD]: "download",
  [ACTIONS.EDIT]: "edit",
  [ACTIONS.PREVIEW]: "preview",
  [ACTIONS.SETTINGS]: "settings",
  [ACTIONS.DELETE]: "delete",
  [ACTIONS.REVOKE]: "revoke"
};

const THEMES = {
  primary: Css.primary,
  warning: Css.warning,
  negative: Css.negative,
  danger: Css.danger,
  success: Css.success,
  medium: Css.medium
};

const MenuItem = ({ component: Component = "div", data, className, onClick }) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    disabled,
    action,
    icon: Icon = ACTION_ICONS[action],
    theme = ACTION_THEMES[action],
    text = uiTexts[ACTION_TEXT_KEYS[action]],
    className: dataClassName,
    ...restProps
  } = data;

  const handleItemClick = useCallback(async(event) => {
    if (onClick) await onClick(action, event);
  }, [action, onClick]);

  return (
    <Component
      disabled={disabled}
      className={classNames(Css.menuItem, THEMES[theme], className, dataClassName)}
      onClick={handleItemClick}
      {...restProps}>
      {!!Icon && <Icon />}
      <span>{text}</span>
    </Component>
  );
};

export default React.memo(MenuItem);
