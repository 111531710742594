import { INPUT_FIELD_NAMES } from "nlib/common/ContactForm/constants";
import { getContactsUniqueCountries } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";
import useContactFormContext from "nlib/common/ContactForm/useContactFormContext";

const ContactFormSelectCountry = (props) => {
  const { uiTexts, countries } = useSelector(getTextsData);

  const { value, disabled, readOnly, onChange } = useContactFormContext(INPUT_FIELD_NAMES.COUNTRY_CODE);

  const recentCountries = useSelector(getContactsUniqueCountries);

  const options = useMemo(() => {
    return Object.entries(countries)
      .map(([code, label]) => ({ value: code, label, group: recentCountries.includes(code) }));
  }, [countries, recentCountries]);

  const groups = useMemo(() => {
    return [{ value: true, label: uiTexts.recent }, { value: false, label: uiTexts.other }];
  }, [uiTexts]);

  return (
    <Select
      placeholder={uiTexts.selectCountry}
      {...props}
      options={options}
      groups={groups}
      disabled={disabled}
      readOnly={readOnly}
      value={value || ""}
      onChange={onChange} />
  );
};

export default React.memo(ContactFormSelectCountry);
