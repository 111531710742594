import Css from "./style.module.scss";

import classNames from "classnames";

import React from "react";

const DropDownMenuDivider = ({ className, label, ...restProps }) => {
  return (
    <div className={classNames(Css.dropDownMenuDivider, className)} {...restProps}>
      {!!label && <div className={Css.label}>{label}</div>}
    </div>
  );
};

export default React.memo(DropDownMenuDivider);
