import Css from "./style.module.scss";

import DropDownContext from "nlib/ui/DropDown/DropDownContext";
import React, { useCallback, useContext, useRef } from "react";
import classNames from "classnames";

const DropDownMenuItem = (props) => {
  const {
    value,
    disabled,
    selected,
    children,
    className,
    closeOnClick = true,
    onClick,
    ...restProps
  } = props;

  const itemRef = useRef();

  const context = useContext(DropDownContext);

  const handleClick = useCallback((event) => {
    if (onClick && !disabled) onClick(value, event);
    if (!context || !closeOnClick) return;

    const { setOpened } = context;

    setOpened(false);
  }, [value, disabled, context, closeOnClick, onClick]);

  return (
    <div
      {...restProps}
      ref={itemRef}
      className={classNames(
        Css.dropDownMenuItem,
        selected && Css.selected,
        onClick && Css.clickable,
        className
      )}
      disabled={disabled}
      onClick={handleClick}>
      {children}
    </div>
  );
};

export default React.memo(DropDownMenuItem);
