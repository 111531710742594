import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useCallback } from "react";
import classNames from "classnames";

const Item = ({ value, highlight, index, disabled, onRemove }) => {
  const content = value.label || value;

  const handleRemoveClick = useCallback(() => {
    if (!disabled) onRemove(index);
  }, [index, disabled, onRemove]);

  return (
    <div
      title={content}
      className={classNames(Css.item, disabled && Css.disabled, highlight && Css.highlight)}
      onClick={handleRemoveClick}>
      <div className={Css.content} title={content}>{content}</div>
      <div className={Css.remove}>
        <Icons.X />
      </div>
    </div>
  );
};

export default React.memo(Item);

