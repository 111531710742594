import CountryStates from "const/CountryStates";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";

const SelectUsState = (props) => {
  const options = useMemo(() => {
    const statesList = Object.values(CountryStates.US);

    return statesList.map((value) => ({ value, label: value }));
  }, []);

  return (
    <Select {...props} options={options} />
  );
};

export default React.memo(SelectUsState);
