import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DatePickerLocalized from "nlib/common/DatePickerLocalized";
import Input from "nlib/ui/Input";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { DATE_FORMATS } = Constants;

const REGEXP = [
  /(?<d>\d{2})[.,:; -](?<m>\d{2})[.,:; -](?<y>\d{4})/,
  /(?<y>\d{4})[.,:; -](?<m>\d{2})[.,:; -](?<d>\d{2})/,
  /(?<m>\d{2})\/(?<d>\d{2})\/(?<y>\d{4})/
];

const getDateStringFromMatch = (match) => {
  const { y: yy, m: mm, d: dd } = match.groups;

  return `${yy}-${mm}-${dd}`;
};

const parseInputValue = (value) => {
  if (!value) return null;

  const match = REGEXP.map((regex) => value.match(regex)).find(Boolean);

  if (!match) return null;

  const result = getDateStringFromMatch(match);

  return Date.parse(result) ? result : null;
};

const DatePickerInput = (props) => {
  const {
    value,
    disabled,
    placeholder,
    error,
    invalid,
    valid,
    focused,
    active,
    readOnly,
    openToDate,
    autoFocus = false,
    className,
    onFocus,
    onChange,
    ...restProps
  } = props;

  const [inputValue, setInputValue] = useState("");

  const [opened, setOpened] = useState(false);

  const activeOrganization = useSelector(getActiveOrganization);

  const dateFormat = activeOrganization.countryCode === Countries.US ? DATE_FORMATS.US : DATE_FORMATS.EU;

  const setInputValueFormatted = useCallback((string) => {
    setInputValue(string ? moment(string).format(dateFormat) : "");
  }, [dateFormat]);

  const handleInputChange = useCallback((newValue) => {
    setInputValue(newValue);

    const parsed = parseInputValue(newValue);

    if (parsed) {
      onChange(parsed);
      setOpened(false);
    } else {
      onChange("");
    }
  }, [onChange, setOpened]);

  const handleClearClick = useCallback(() => {
    setInputValue("");
    onChange("");
  }, [onChange]);

  const handleInputFocus = useCallback((event) => {
    if (Utils.checkIsTouchDevice()) event.target.blur();
    if (onFocus) onFocus(event);
  }, [onFocus]);

  useEffect(() => {
    setInputValueFormatted(value || "");
  }, [value, setInputValueFormatted]);

  useEffect(() => {
    if (autoFocus) setOpened(autoFocus);
  }, [autoFocus]);

  const toggleButton = useMemo(() => {
    return (
      <>
        <Input
          placeholder={placeholder}
          value={inputValue}
          focused={focused}
          disabled={disabled}
          active={active}
          valid={valid}
          error={error}
          invalid={invalid}
          readOnly={readOnly}
          iconBefore={Icons.CalendarBlank}
          onFocus={handleInputFocus}
          onChange={handleInputChange} />
        {!!value && !readOnly && (
          <span
            className={Css.clear}
            onClick={handleClearClick}>
            <Icons.X />
          </span>
        )}
      </>
    );
  }, [
    focused,
    disabled,
    active,
    value,
    error,
    invalid,
    valid,
    readOnly,
    inputValue,
    placeholder,
    handleClearClick,
    handleInputFocus,
    handleInputChange
  ]);

  return (
    <DatePickerLocalized
      {...restProps}
      value={value || (openToDate ? Utils.formatApiDate(openToDate) : value)}
      opened={opened}
      disabled={disabled}
      readOnly={readOnly}
      className={classNames(Css.datePickerInput, className)}
      toggleButton={toggleButton}
      onChange={onChange}
      setOpened={setOpened} />
  );
};

export default React.memo(DatePickerInput);
