import Css from "./style.module.scss";

import React, { useMemo } from "react";
import classNames from "classnames";
import colorInterpolate from "color-interpolate";
import stringToColor from "string-to-color";

const ABBR_LENGTH = 2;

const INTERPOLATION_COLOR = "#becad6";

const INTERPOLATE_INDEX = 0.5;

const Avatar = (props) => {
  const {
    src,
    title = "",
    color,
    children,
    className,
    backgroundColor,
    iconComponent: IconComponent,
    ...restProps
  } = props;

  const abbr = useMemo(() => {
    if (!title) return null;

    return title
      .trim()
      .split(/\s/)
      .map((str) => str.replace(/\W/g, ""))
      .filter(Boolean)
      .slice(0, ABBR_LENGTH).map((part) => part[0])
      .join("").toUpperCase();
  }, [title]);

  const style = useMemo(() => ({
    color,
    backgroundColor: backgroundColor
      || (src ? "" : colorInterpolate([stringToColor(title), INTERPOLATION_COLOR])(INTERPOLATE_INDEX))
  }), [src, title, backgroundColor, color]);

  return (
    <div
      className={classNames(Css.avatar, className)}
      style={style}
      {...restProps}>
      {src
        ? <img src={src} alt={title} />
        : (IconComponent ? <IconComponent /> : <span className={Css.abbr}>{abbr}</span>)}
    </div>
  );
};

export default React.memo(Avatar);
