import Css from "./style.module.scss";

import { LAST_MONTH_INDEX, MONTH_NAMES, WEEK_DAYS, WEEK_START } from "nlib/ui/DatePicker/constants";
import DatePickerContainer from "nlib/ui/DatePicker/lib/DatePickerContainer";
import DatePickerContext from "nlib/ui/DatePicker/lib/DatePickerContext";
import DatePickerDay from "nlib/ui/DatePicker/lib/DatePickerDay";
import DatePickerHeader from "nlib/ui/DatePicker/lib/DatePickerHeader";
import React, { useCallback, useContext } from "react";

const DatePickerDays = ({ range, translations, minDate, maxDate, secondView, onSelect }) => {
  const {
    startDateObject,
    endDateObject,
    currentYear: currentYearString,
    currentMonth: currentMonthString,
    setCurrentYear,
    setCurrentMonth,
    setMonthsView
  } = useContext(DatePickerContext);

  const dayNamesTranslation = (translations && translations.days) || WEEK_DAYS;

  const monthNamesTranslation = (translations && translations.months) || MONTH_NAMES;

  const [selectedYear, selectedMonth, selectedDay] = startDateObject
    ? [startDateObject.getFullYear(), startDateObject.getMonth(), startDateObject.getDate()] : [];

  const [selectedEndYear, selectedEndMonth, selectedEndDay] = endDateObject
    ? [endDateObject.getFullYear(), endDateObject.getMonth(), endDateObject.getDate()] : [];

  const weekStartIndex = WEEK_DAYS.indexOf(WEEK_START);

  const dateObject = new Date(currentYearString, currentMonthString + (secondView ? 1 : 0), 1);

  const currentYear = dateObject.getFullYear();

  const currentMonth = dateObject.getMonth();

  const previousMonth = currentMonth > 0 ? currentMonth - 1 : LAST_MONTH_INDEX;

  const handleMonthShift = useCallback((shift) => {
    setCurrentMonth((prev) => {
      const next = prev + shift;

      if (next < 0) {
        setCurrentYear(currentYearString - 1);

        return LAST_MONTH_INDEX;
      }
      if (next > LAST_MONTH_INDEX) {
        setCurrentYear(currentYearString + 1);

        return 0;
      }

      return next;
    });
  }, [currentYearString, setCurrentMonth, setCurrentYear]);

  const handlePrevClick = useCallback(() => handleMonthShift(-1), [handleMonthShift]);

  const handleNextClick = useCallback(() => handleMonthShift(1), [handleMonthShift]);

  while (dateObject.getDay() !== weekStartIndex) { // eslint-disable-line no-loops/no-loops
    if (isNaN(dateObject.getDay())) {
      throw new TypeError("isNaN(dateObject.getDay())");
    }
    dateObject.setDate(dateObject.getDate() - 1);
  }

  const days = [];

  const dayNamesArray = [];

  let month;

  let date;

  // eslint-disable-next-line no-loops/no-loops
  do {
    month = dateObject.getMonth();
    date = dateObject.getDate();
    if (dayNamesArray.length < WEEK_DAYS.length) {
      dayNamesArray.push(dayNamesTranslation[dateObject.getDay()]);
    }
    days.push({
      year: currentYear,
      month,
      date,
      muted: month !== currentMonth,
      disabled: (minDate && new Date(currentYear, month, date) < minDate)
        || (maxDate && new Date(currentYear, month, date) > maxDate),
      selected: currentYear === selectedYear
        && month === selectedMonth
        && date === selectedDay,
      rangeEnd: currentYear === selectedEndYear
        && month === selectedEndMonth
        && date === selectedEndDay,
      highlighted: dateObject > startDateObject && dateObject < endDateObject,
      today: dateObject.toDateString() === new Date().toDateString()
    });
    dateObject.setDate(dateObject.getDate() + 1);

    if (isNaN(dateObject.getMonth())) {
      throw new TypeError("isNaN(dateObject.getMonth())");
    }
  } while (
    dateObject.getMonth() === previousMonth
    || dateObject.getMonth() === currentMonth
    || dateObject.getDay() !== weekStartIndex
  );

  return (
    <DatePickerContainer className={Css.datePickerDays}>
      <DatePickerHeader
        label={`${monthNamesTranslation[currentMonth]} ${currentYear}`}
        onLabelClick={setMonthsView}
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick} />
      <div className={Css.dayNames}>
        {dayNamesArray.map((name) => (
          <div key={name} className={Css.dayName}>{name}</div>
        ))}
      </div>
      <div className={Css.days}>
        {days.map((item) => (
          <DatePickerDay
            key={`${item.month}.${item.date}`}
            range={range}
            item={item}
            onSelect={onSelect} />
        ))}
      </div>
    </DatePickerContainer>
  );
};

export default React.memo(DatePickerDays);
