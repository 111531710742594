import Css from "../../style.module.scss";

import { FormElement } from "nlib/common/Form";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Checkbox from "nlib/ui/Checkbox";
import FormLabel from "nlib/common/FormLabel";
import React from "react";
import SelectDefaultAccountant from "../SelectDefaultAccountant";

const UncategorizedTab = ({ businessId, hasBusinessUsers }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const { businessOrganization } = useSelector(getActiveOrganization);

  return (
    <>
      <div className={Css.block}>
        <FormLabel>{uiTexts.transactions}</FormLabel>
        <div className={Css.row}>
          <FormElement
            toggle
            name="allowApproveWithoutPayee"
            element={Checkbox}>
            <span>{messages.approveWithoutPayeeToggleLabelText}</span>
          </FormElement>
        </div>
      </div>
      {(!businessOrganization || hasBusinessUsers) && (
        <div className={Css.block}>
          <FormLabel>{messages.accountantToAskClient}</FormLabel>
          <div className={Css.row}>
            <FormElement
              businessId={businessId}
              name="defaultClientAccountantEmail"
              element={SelectDefaultAccountant} />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(UncategorizedTab);
