import Css from "./style.module.scss";

import React, { useLayoutEffect, useRef } from "react";
import classNames from "classnames";

const PLACES = {
  top: "top",
  bottom: "bottom",
  right: "right",
  left: "left"
};

const STYLES = {
  [PLACES.top]: Css.top,
  [PLACES.bottom]: Css.bottom,
  [PLACES.right]: Css.right,
  [PLACES.left]: Css.left
};

const CONTENT_DEFAULT_INDENT = 8;

const Tooltip = ({ className, contentClassName, opened = false, place = PLACES.top, simple, children, ...restProps }) => {
  const contentRef = useRef(null);

  useLayoutEffect(() => {
    if (opened && contentRef.current) {
      if (place === PLACES.left || place === PLACES.right) {
        const { bottom } = contentRef.current.getBoundingClientRect();

        const { innerHeight: windowHeight } = window;

        const maxBottomValue = windowHeight - CONTENT_DEFAULT_INDENT;

        if (bottom > maxBottomValue) {
          contentRef.current.style.marginTop = `${maxBottomValue - bottom}px`;
        }
      }
    }
  }, [opened, place]);

  return !!opened && (
    <div
      className={classNames(Css.tooltip, simple && Css.simple, STYLES[place], className)}
      {...restProps}>
      <div ref={contentRef} className={classNames(Css.content, contentClassName)}>{children}</div>
      <div className={Css.arrow} />
    </div>
  );
};

export default React.memo(Tooltip);
