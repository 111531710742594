import Css from "./style.module.scss";

import { Badge } from "nlib/ui";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import moment from "moment";

const SuperAdminOrganizationInfoBadge = ({
  name,
  countryCode,
  lastActiveAt,
  billingId,
  subscriptionId,
  customBilling,
  businessesCount = 0
}) => {
  const lastActiveDaysAgo = useMemo(() => {
    return moment().diff(lastActiveAt, "days").toString().padStart("3", "0");
  }, [lastActiveAt]);

  const subscriptionMode = useMemo(() => {
    return [
      billingId && subscriptionId && "active",
      billingId && !subscriptionId && "inactive",
      customBilling && "custom",
      "notset"
    ].filter(Boolean)[0];
  }, [billingId, subscriptionId, customBilling]);

  const title = useMemo(() => {
    return {
      active: "Active subscription",
      inactive: "Inactive subscription",
      custom: "Custom billing",
      notset: "Billing not set"
    }[subscriptionMode];
  }, [subscriptionMode]);

  const businessCountTemplate = useMemo(() => {
    return "000".slice(businessesCount.toString().length) + businessesCount;
  }, [businessesCount]);

  const info = useMemo(() => {
    return [countryCode, `#${businessCountTemplate}`, `~${lastActiveDaysAgo}`];
  }, [countryCode, businessCountTemplate, lastActiveDaysAgo]);

  const handleClick = useCallback((event) => {
    const stripUrl = `https://dashboard.stripe.com${Utils.checkIsStagingEnv() ? "/test" : ""}`;

    event.stopPropagation();
    if (billingId) {
      window.open(`${stripUrl}/customers/${billingId}`, "_blank");
    } else {
      window.open(`${stripUrl}/search?query=${name}`, "_blank");
    }
  }, [name, billingId]);

  return (
    <Badge
      data-mode={subscriptionMode}
      title={title}
      className={Css.superAdminOrganizationInfoBadge}
      onClick={handleClick}>
      {info.map((item) => <span key={item}>{item}</span>)}
    </Badge>
  );
};

export default React.memo(SuperAdminOrganizationInfoBadge);
