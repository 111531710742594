import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Checkbox, DropDown } from "nlib/ui";
import { DropDownCaret, DropDownContent, DropDownToggle } from "nlib/ui/DropDown";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FormContext from "contexts/FormContext";
import React, { useCallback, useContext, useMemo, useState } from "react";
import RpaFrequencies from "const/RpaFrequencies";
import Utils from "utils/Utils";
import classNames from "classnames";

const { HOURLY, DAILY, WEEKLY, MONTHLY } = RpaFrequencies;

const SelectRpaFrequency = ({ value, disabled, onChange }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const { values: { rpaPaused }, onChange: onFormChange } = useContext(FormContext);

  const [opened, setOpened] = useState(false);

  const labels = useMemo(() => {
    return {
      [HOURLY]: uiTexts.hourly,
      [DAILY]: `${uiTexts.daily} (${messages.frequencyDaily.toLowerCase()})`,
      [WEEKLY]: `${uiTexts.weekly} (${messages.frequencyWeekly.toLowerCase()})`,
      [MONTHLY]: `${uiTexts.monthly} (${messages.frequencyMonthly.toLowerCase()})`
    };
  }, [uiTexts, messages]);

  const handleRpaPausedToggleChange = useCallback(() => {
    onFormChange({ name: "rpaPaused", value: !rpaPaused });
  }, [rpaPaused, onFormChange]);

  const handleOptionClick = useCallback((event) => {
    onChange(event.currentTarget.dataset.value);
    setOpened(false);
  }, [onChange]);

  return (
    <div className={Css.selectRpaFrequency}>
      <DropDown
        opened={opened}
        setOpened={setOpened}
        className={Css.dropdown}
        disabled={disabled || rpaPaused}>
        <DropDownToggle className={classNames(Css.toggle)}>
          <span>{rpaPaused ? uiTexts.roboticAiIsPaused : (labels[value] || uiTexts.selectValue)}</span>
          <DropDownCaret />
        </DropDownToggle>
        <DropDownContent className={Css.dropDownContent}>
          {Utils.checkIsStagingEnv() && (
            <div
              data-value={HOURLY}
              className={classNames(Css.option, (value === HOURLY) && Css.active)}
              onClick={handleOptionClick}>
              <div className={Css.circle} />
              <div className={Css.text}>{uiTexts.hourly}</div>
            </div>
          )}
          <div
            data-value={DAILY}
            className={classNames(Css.option, (value === DAILY) && Css.active)}
            onClick={handleOptionClick}>
            <div className={Css.circle} />
            <div className={Css.text}>{uiTexts.daily}</div>
            <div className={Css.icon} data-tooltip={messages.rpaFrequencyDaily}><Icons.Info /></div>
          </div>
          <div
            data-value={WEEKLY}
            className={classNames(Css.option, (value === WEEKLY) && Css.active)}
            onClick={handleOptionClick}>
            <div className={Css.circle} />
            <div className={Css.text}>{uiTexts.weekly}</div>
            <div className={Css.icon} data-tooltip={messages.rpaFrequencyWeekly}><Icons.Info /></div>
          </div>
          <div
            data-value={MONTHLY}
            className={classNames(Css.option, (value === MONTHLY) && Css.active)}
            onClick={handleOptionClick}>
            <div className={Css.circle} />
            <div className={Css.text}>{uiTexts.monthly}</div>
            <div className={Css.icon} data-tooltip={messages.rpaFrequencyMonthly}><Icons.Info /></div>
          </div>
        </DropDownContent>
      </DropDown>
      <Checkbox
        toggle
        data-tooltip={rpaPaused ? uiTexts.resume : uiTexts.pause}
        value={!rpaPaused}
        onClick={handleRpaPausedToggleChange} />
    </div>
  );
};

export default React.memo(SelectRpaFrequency);
