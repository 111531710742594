import Css from "./style.module.scss";

import Button from "nlib/ui/Button";
import DatePickerContent from "./lib/DatePickerContent";
import DropDown from "nlib/ui/DropDown";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import DropDownToggle from "nlib/ui/DropDown/lib/DropDownToggle";
import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";

const BEFORE_RENDER_PROPS = { before: true, after: false };

const AFTER_RENDER_PROPS = { after: true, before: false };

const stringToDateObject = (string) => {
  if (!string) return string;

  const [year, month, day] = (typeof string === "string" ? string : "").split("-");

  const date = new Date();

  date.setFullYear(parseInt(year, 10));
  date.setMonth(parseInt(month, 10) - 1);
  date.setDate(parseInt(day, 10));

  return isNaN(date) ? null : date;
};

const dateFormatter = (value, fallback) => {
  if (Array.isArray(value)) {
    return value.filter(Boolean).length
      ? value.join(" - ")
      : fallback;
  }

  return value || fallback;
};

const DatePicker = (props) => {
  const { autoFocus = false } = props;

  const [openedState, setOpenedState] = useState(autoFocus);

  const {
    value,
    disabled,
    alignRight,
    range,
    opened = openedState,
    className,
    dropDownClassName,
    children,
    placeholder,
    toggleButton,
    modal,
    portal,
    readOnly,
    setOpened = setOpenedState,
    onChange,
    ...restProps
  } = props;

  const [startDateObject, endDateObject] = useMemo(() => {
    const [startDateString, endDateString] = range ? value : [value];

    return [
      stringToDateObject(startDateString),
      stringToDateObject(endDateString)
    ];
  }, [value, range]);

  const handleChange = useCallback((date) => {
    if (onChange) {
      if (range) {
        const [start, end] = value;

        if (!start || end) {
          onChange([date]);
        } else if (!end) {
          if (start > date) {
            onChange([date, start]);
          } else {
            onChange([start, date]);
          }
          setOpened(false);
        }
      } else {
        onChange(date);
        setOpened(false);
      }
    }
  }, [range, value, onChange, setOpened]);

  return (
    <DropDown
      dev
      className={classNames(Css.datePicker, className)}
      disabled={disabled}
      opened={!readOnly && opened}
      setOpened={setOpened}>
      <DropDownToggle>
        {toggleButton || (
          <Button outline>
            {dateFormatter(value, placeholder || "Select date")}
          </Button>
        )}
      </DropDownToggle>
      <DropDownContent
        modal={modal}
        portal={portal}
        className={classNames(Css.datePickerDropDown, dropDownClassName)}
        alignRight={alignRight}>
        {!!children && children(BEFORE_RENDER_PROPS)}
        <div className={Css.mainContent}>
          <DatePickerContent
            {...restProps}
            range={range}
            startDateObject={startDateObject}
            endDateObject={endDateObject}
            onChange={handleChange} />
        </div>
        {!!children && children(AFTER_RENDER_PROPS)}
      </DropDownContent>
    </DropDown>
  );
};

export default React.memo(DatePicker);
