import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAccountsData } from "selectors/accounts";
import { getSelectedBusinessCategories, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import IntegrationServicesCategories from "const/IntegrationServicesCategories";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";

const GROUP_TO_THEME_MAP = {
  asset: "primary",
  expense: "negative",
  revenue: "positive",
  income: "positive",
  liability: "warning",
  equity: "primary"
};

const SUB_CATEGORY_DELIMETER = {
  [IntegrationServices.QUICK_BOOKS.value]: ":",
  [IntegrationServices.QUICK_BOOKS_DESKTOP.value]: ":"
};

const CODE_REGEX = /^[\d\w]+:\s/;

const getGroupName = (integrationService, group) => IntegrationServicesCategories.getGroupLangId(integrationService, group);

const SelectCategoryInput = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    value = {},
    onChange,
    placeholder = uiTexts.selectCategory,
    validate,
    entityPaymentType,
    assetAccountsOnly,
    iconBefore = Icons.SquaresFour,
    iconBeforeTitle = uiTexts.category,
    onAutoComplete,
    ...restProps
  } = props;

  const { extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const [showAll, setShowAll] = useState(false);

  const { displayName: categoryName = "" } = value;

  const categories = useSelector(getSelectedBusinessCategories);

  const accounts = useSelector(getAccountsData);

  const paymentAccountsByName = useMemo(() => {
    return accounts.reduce((aggregator, { name: accountName, extraData: { paymentAccount = false } = {} }) => {
      aggregator[accountName] = aggregator[accountName] || paymentAccount;

      return aggregator;
    }, {});
  }, [accounts]);

  const categoriesList = useMemo(() => {
    return [...categories]
      .filter(({ group }) => {
        const conditionA = !entityPaymentType
          || IntegrationServicesCategories.checkIsAvailable(integrationService, group, entityPaymentType);

        const conditionB = !assetAccountsOnly || (group === "asset" || paymentAccountsByName[categoryName]);

        return conditionA && conditionB;
      })
      .sort((
        { displayName: displayNameA, group: groupA },
        { displayName: displayNameB, group: groupB }
      ) => {
        const groupLangIdA = getGroupName(integrationService, groupA);

        const groupLangIdB = getGroupName(integrationService, groupB);

        displayNameA = displayNameA.slice(CODE_REGEX, "");

        displayNameB = displayNameB.slice(CODE_REGEX, "");

        return groupLangIdA.localeCompare(groupLangIdB) || displayNameA.localeCompare(displayNameB);
      })
      .map(({ code: val, displayName, group }) => {
        return { value: val, label: displayName, group };
      });
  }, [categories, entityPaymentType, integrationService, assetAccountsOnly, paymentAccountsByName, categoryName]);

  const filteredCategoriesList = useMemo(() => {
    if (!categoryName || showAll) return categoriesList;

    const localValue = categoryName.trim().toLowerCase();

    return categoriesList.filter((item) => {
      if (!item.label) return false;

      const itemValue = item.label.trim().toLowerCase();

      if (itemValue === localValue || itemValue.includes(localValue)) {
        return true;
      }

      const similarityCheck = Utils.checkSimilarityBy(
        { value: categoryName },
        { value: item.label },
        Constants.DATA_SIMILARITY_COEFFICIENT
      );

      if (similarityCheck) return true;

      const delimeter = SUB_CATEGORY_DELIMETER[integrationService];

      const startsWith = `${item.label}${delimeter}`;

      return similarityCheck || categoriesList
        .filter(({ label }) => label.startsWith(startsWith))
        .some(({ label }) => {
          const labelLowered = label.toLowerCase();

          return labelLowered === localValue || labelLowered.includes(localValue);
        });
    });
  }, [categoryName, showAll, categoriesList, integrationService]);

  const renderLabel = useCallback(({ label, group }) => {
    const groupTextKey = IntegrationServicesCategories
      .getGroupLangId(integrationService, group);

    const delimeter = SUB_CATEGORY_DELIMETER[integrationService];

    const [code] = CODE_REGEX.exec(label) || [""];

    let name = label.replace(CODE_REGEX, "");

    const splitted = name.split(delimeter);

    name = `${code}${splitted.pop()}`;

    return (
      <div className={Css.listItem} title={label}>
        <span className={Css.name}>
          {!!splitted.length && (
            [...new Array(splitted.length)].map((skip, index) => {
              return <span key={String(index + 1)} className={Css.indent} />;
            })
          )}
          <span>{name}</span>
        </span>
        <Badge className={Css.badge} theme={GROUP_TO_THEME_MAP[group]}>
          {uiTexts[groupTextKey] || group.toUpperCase()}
        </Badge>
      </div>
    );
  }, [integrationService, uiTexts]);

  const handleChange = useCallback((newName, event) => {
    onChange({ displayName: newName }, event);
  }, [onChange]);

  const handleAutoComplete = useCallback((selected, event) => {
    if (onAutoComplete) onAutoComplete(selected, event);

    const found = Utils.arrayFind(categories, "code", selected.value);

    onChange(found || {}, event);
  }, [categories, onChange, onAutoComplete]);

  return (
    <AutoCompleteInput
      {...(validate ? {
        valid: !!(value.displayName && value.code),
        invalid: !!(value.displayName && !value.code)
      } : {})}
      {...restProps}
      iconBefore={iconBefore}
      iconBeforeTitle={iconBeforeTitle}
      value={categoryName}
      placeholder={placeholder}
      showAll={showAll}
      renderLabel={renderLabel}
      items={categoriesList}
      filteredItems={filteredCategoriesList}
      onSetShowAll={setShowAll}
      onChange={handleChange}
      onAutoComplete={handleAutoComplete} />
  );
};

export default React.memo(SelectCategoryInput);
