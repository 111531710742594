import * as Yup from "yup";
import { INPUT_FIELD_NAMES, INPUT_FIELD_NAMES_ARRAY } from "./constants";
import { getContactsData } from "selectors/contacts";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { useSelector } from "react-redux";
import ContactFormContext from "contexts/ContactFormContext";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import Utils from "utils/Utils";

const ACTION_TYPES = {
  UPDATE: "update",
  INPUT_CHANGE: "inputChange"
};

const VALIDATION_SCHEMA = Yup.object().shape({
  subType: Yup.string().when("$quickBooksBusiness", {
    is: true,
    then: Yup.string().oneOf([...Object.values(DataConstants.CONTACT_SUB_TYPES)]).required()
  }),
  email: Yup.string().trim().email(),
  name: Yup.string().trim().required()
});

const getInitialState = ({ contactData = {}, prefillData = {} }) => {
  return {
    ...INPUT_FIELD_NAMES_ARRAY.reduce((result, key) => {
      const { [key]: value = prefillData[key] } = contactData;

      return { ...result, [key]: value };
    }, {}),
    id: contactData.id || prefillData.id,
    type: contactData.type || DataConstants.CONTACT_TYPES.LEGAL_PERSON
  };
};

const reducer = (state, { type, payload }) => {
  const { UPDATE, INPUT_CHANGE } = ACTION_TYPES;

  switch (type) {
    case UPDATE: {
      return payload;
    }
    case INPUT_CHANGE: {
      const [field, value] = payload;

      switch (field) {
        case INPUT_FIELD_NAMES.VAT_ID:
          return {
            ...state,
            vatId: value,
            type: state.type || DataConstants.CONTACT_TYPES.LEGAL_PERSON
          };
        case INPUT_FIELD_NAMES.COUNTRY_CODE:
          return { ...state, countryCode: value, state: "" };
        default:
          if (field) {
            return { ...state, [field]: value };
          }

          return { ...state, ...(value || null) };
      }
    }
    default:
      return state;
  }
};

const ContactForm = React.memo((props) => {
  const [disabledState, setDisabledState] = useState(false);

  const {
    className,
    vendorId,
    willClose,
    prefillData = {},
    compact,
    disabled = disabledState,
    setDisabled = setDisabledState,
    readOnly,
    children,
    wrapper: Wrapper = "div",
    onChange,
    onSubmit,
    onClose
  } = props;

  const contactsData = useSelector(getContactsData);

  const quickBooksBusiness = !!(useSelector(getCurrentQuickBooksRealmId));

  const contactData = useMemo(() => {
    return (vendorId && contactsData.find(({ id }) => id === vendorId)) || undefined;
  }, [vendorId, contactsData]);

  const initialState = useMemo(() => {
    return getInitialState({ contactData, prefillData });
  }, [contactData, prefillData]);

  const localReducer = useCallback((state, action) => {
    const newState = reducer(state, action);

    if (onChange) {
      setTimeout(() => onChange(newState, action), 0);
    }

    return newState;
  }, [onChange]);

  const [localState, localDispatch] = useReducer(localReducer, initialState);

  const hasChanges = useMemo(() => {
    return !Utils.deepEqual(localState, initialState);
  }, [initialState, localState]);

  const validState = useMemo(() => {
    return VALIDATION_SCHEMA.isValidSync(localState, { context: { quickBooksBusiness } });
  }, [localState, quickBooksBusiness]);

  const updateForm = useCallback((payload) => {
    localDispatch({ type: ACTION_TYPES.UPDATE, payload });
  }, []);

  const handleInputChange = useCallback((fieldName, value) => {
    localDispatch({ type: ACTION_TYPES.INPUT_CHANGE, payload: [fieldName, value] });
  }, []);

  const handleFormSubmit = useCallback(async(event) => {
    if (event) event.preventDefault();
    if (!onSubmit) return;
    setDisabled(true);
    await onSubmit({ state: localState });
    setDisabled(false);
  }, [localState, onSubmit, setDisabled]);

  const providedContext = useMemo(() => ({
    disabled,
    hasChanges,
    validState,
    readOnly,
    vendorId,
    contactData,
    updateForm,
    state: localState,
    onInputChange: handleInputChange,
    onSubmit: handleFormSubmit
  }), [
    hasChanges,
    disabled,
    readOnly,
    validState,
    vendorId,
    localState,
    contactData,
    updateForm,
    handleInputChange,
    handleFormSubmit
  ]);

  useEffect(() => {
    if (willClose) onClose({ hasChanges, validState, onSubmit: handleFormSubmit });
  }, [localState, willClose, initialState, validState, hasChanges, onClose, handleFormSubmit]);

  return (
    <ContactFormContext.Provider value={providedContext}>
      {Wrapper
        ? (
          <Wrapper data-compact={compact} className={className}>
            {children(providedContext)}
          </Wrapper>
        )
        : children(providedContext)}
    </ContactFormContext.Provider>
  );
});

export { default as ContactFormNameInput } from "./lib/ContactFormNameInput";
export { default as ContactFormSubTypeSelect } from "./lib/ContactFormSubTypeSelect";
export { default as ContactFormSelectCountry } from "./lib/ContactFormSelectCountry";
export { default as ContactFormSelectState } from "./lib/ContactFormSelectState";
export { default as ContactFormCityInput } from "./lib/ContactFormCityInput";
export { default as ContactFormStreetInput } from "./lib/ContactFormStreetInput";
export { default as ContactFormZipCodeInput } from "./lib/ContactFormZipCodeInput";
export { default as ContactFormPhoneInput } from "./lib/ContactFormPhoneInput";
export { default as ContactFormEmailInput } from "./lib/ContactFormEmailInput";
export { default as ContactFormTypeSelect } from "./lib/ContactFormTypeSelect";

export default React.memo(ContactForm);
