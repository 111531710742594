import Constants from "const/Constants";
import Input from "nlib/ui/Input";
import React, { useCallback, useRef } from "react";

const DebounceInput = (props) => {
  const {
    element: Element = Input,
    onChange,
    onInputComplete,
    ...restProps
  } = props;

  const changeTimeoutIdRef = useRef();

  const handleChange = useCallback((value, event, reset) => {
    if (onChange) onChange(value, event);
    clearTimeout(changeTimeoutIdRef.current);
    if (reset) {
      onInputComplete(value, event);
    } else {
      changeTimeoutIdRef.current = setTimeout(() => {
        if (onInputComplete) onInputComplete(value, event);
      }, Constants.TEXT_FIELD_DEBOUNCE_TIMEOUT);
    }
  }, [onChange, onInputComplete]);

  return (
    <Element
      {...restProps}
      onChange={handleChange} />
  );
};

export default React.memo(DebounceInput);
