import Css from "./style.module.scss";

import React, { useMemo } from "react";
import TableContext from "./TableContext";
import classNames from "classnames";

const THEMES = {
  flat: Css.flat
};

const Table = (props) => {
  const {
    disabled,
    children,
    className,
    theme,
    hover = true,
    sortBy,
    sortOrder,
    forwardedRef,
    onSortChange,
    ...restProps
  } = props;

  const contextValue = useMemo(() => {
    return { sortBy, sortOrder, onSortChange };
  }, [sortBy, sortOrder, onSortChange]);

  return (
    <TableContext.Provider value={contextValue}>
      <div
        disabled={disabled}
        {...restProps}
        ref={forwardedRef}
        className={classNames(Css.table, THEMES[theme], hover && Css.hover, className)}>
        {children}
      </div>
    </TableContext.Provider>
  );
};

export default React.memo(Table);

export { default as TableCell } from "./lib/TableCell";
export { default as TableHead } from "./lib/TableHead";
export { default as TableRow } from "./lib/TableRow";
