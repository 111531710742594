import { useEffect } from "react";
import BrowserEvents from "const/BrowserEvents";

const { RESIZE, FULLSCREEN_CHANGE, ORIENTATION_CHANGE } = BrowserEvents;

const useWindowResize = (handler) => {
  useEffect(() => {
    window.addEventListener(RESIZE, handler);
    window.addEventListener(FULLSCREEN_CHANGE, handler);
    window.addEventListener(ORIENTATION_CHANGE, handler);

    return () => {
      window.removeEventListener(RESIZE, handler);
      window.removeEventListener(FULLSCREEN_CHANGE, handler);
      window.removeEventListener(ORIENTATION_CHANGE, handler);
    };
  }, [handler]);
};

export default useWindowResize;
