import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Modal } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import SelectTaxRateInput from "nlib/common/SelectTaxRateInput";

const SelectTaxRateWindow = ({ onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [taxRate, setTaxRate] = useState({});

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleTaxRateChange = useCallback((value) => {
    setTaxRate(value);
  }, []);

  const handleConfirmClick = useCallback(() => {
    onClose(taxRate);
  }, [taxRate, onClose]);

  const handleCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      dialogClassName={Css.selectTaxRateWindow}
      title={uiTexts.selectTaxRate}
      iconComponent={Icons.Percent}
      onClose={handleClose}>
      <SelectTaxRateInput
        value={taxRate}
        invalid={!taxRate.id}
        valid={taxRate.id}
        onChange={handleTaxRateChange} />
      <ModalFooter>
        <Button
          primary large
          disabled={!taxRate.id}
          onClick={handleConfirmClick}>
          {uiTexts.save}
        </Button>
        <Button large onClick={handleCancelClick}>
          {uiTexts.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectTaxRateWindow);
