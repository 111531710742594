import Css from "./style.module.scss";

import { CSSTransition as CssTransition } from "react-transition-group";
import React from "react";
import classNames from "classnames";

const DEFAULT_TIMEOUT = 300;

const Popup = (props) => {
  const {
    shown,
    timeout = DEFAULT_TIMEOUT,
    className,
    children
  } = props;

  return (
    <div className={classNames(Css.popup, className)}>
      <CssTransition
        unmountOnExit
        in={shown}
        classNames={{
          enter: Css.animationEnter,
          enterActive: Css.animationEnterActive,
          exit: Css.animationExit,
          exitActive: Css.animationExitActive
        }}
        timeout={timeout}>
        {children}
      </CssTransition>
    </div>
  );
};

export default React.memo(Popup);
