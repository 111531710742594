import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserBusinessesData } from "selectors/businesses";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

const useAddNewBusiness = () => {
  const dispatch = useDispatch();

  const { messages, uiTexts } = useSelector(getTextsData);

  const { id: activeOrganizationId } = useSelector(getActiveOrganization);

  const businessesData = useSelector(getUserBusinessesData);

  return useCallback(async(params) => {
    const { integrationService } = params;

    const manualIntegrationFlow = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

    dispatch(UiActions.togglePreloader(true));
    if (manualIntegrationFlow) {
      dispatch(UiActions.togglePreloader(false));

      dispatch(UiActions.showAddQuickBooksDesktopWindow());
    } else {
      Utils.storageValue(
        `${Constants.LS_KEYS.NEW_BUSINESS_RPA_MODE}_${activeOrganizationId}`,
        params.rpaMode ? true : null,
        true
      );

      const consentUrl = await dispatch(IntegrationsActions.buildConsentUrl(integrationService));

      if (consentUrl) {
        window.location.href = consentUrl;

        return;
      } dispatch(UiActions.togglePreloader(false));
    }

    if (!manualIntegrationFlow && !businessesData.length) {
      await dispatch(UiActions.showModal(messages.addFirstBusiness, uiTexts.warning));
    }
  }, [activeOrganizationId, businessesData.length, dispatch, messages.addFirstBusiness, uiTexts.warning]);
};

export default useAddNewBusiness;
