import { INPUT_FIELD_NAMES } from "nlib/common/ContactForm/constants";
import { getCurrentQuickBooksRealmId, getCurrentXeroOrganizationId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useMemo } from "react";
import Select from "nlib/ui/Select";
import Switcher from "nlib/ui/Switcher";
import useContactFormContext from "nlib/common/ContactForm/useContactFormContext";

const { CONTACT_SUB_TYPES } = DataConstants;

const { VENDOR, CUSTOMER } = CONTACT_SUB_TYPES;

const SUB_TYPE_TO_TEXT_MAP = {
  [VENDOR]: "vendor",
  [CUSTOMER]: "customer"
};

const ContactFormSubTypeSelect = ({ useSwitcher, disabled: disabledProp, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!(useSelector(getCurrentQuickBooksRealmId));

  const xeroBusiness = !!(useSelector(getCurrentXeroOrganizationId));

  const { contactData, value = null, disabled, readOnly, onChange }
    = useContactFormContext(INPUT_FIELD_NAMES.SUB_TYPE);

  const options = useMemo(() => {
    return [
      xeroBusiness && {
        value: null,
        label: `${uiTexts.all} (${uiTexts.vendor.toLowerCase()}/${uiTexts.customer.toLowerCase()})`
      },
      ...Object.values(CONTACT_SUB_TYPES)
        .map((type) => ({
          value: type,
          label: uiTexts[SUB_TYPE_TO_TEXT_MAP[type]]
        }))
    ].filter(Boolean);
  }, [uiTexts, xeroBusiness]);

  const Element = useSwitcher ? Switcher : Select;

  return (
    <Element
      {...restProps}
      placeholder={uiTexts.selectType}
      invalid={quickBooksBusiness && !value}
      disabled={disabled || disabledProp || (xeroBusiness ? contactData?.id : contactData?.subType)}
      readOnly={readOnly}
      value={value}
      options={options}
      onChange={onChange} />
  );
};

export default React.memo(ContactFormSubTypeSelect);
