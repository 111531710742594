import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import Button from "nlib/ui/Button";
import DropDown from "nlib/ui/DropDown";
import DropDownCaret from "nlib/ui/DropDown/lib/DropDownCaret";
import DropDownContent from "nlib/ui/DropDown/lib/DropDownContent";
import DropDownMenuItem from "nlib/ui/DropDown/lib/DropDownMenuItem";
import DropDownToggle from "nlib/ui/DropDown/lib/DropDownToggle";
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";

const Select = (props) => {
  const containerRef = useRef();

  const dropdownRef = useRef();

  const {
    active,
    alignRight,
    buttonClassName,
    className,
    disabled,
    disableSelected,
    dropDownClassName,
    groups = [null],
    iconBefore,
    invalid,
    modal,
    multiple,
    options = [],
    placeholder,
    portal,
    prepend,
    readOnly,
    renderLabel,
    title,
    useCaret = true,
    valid,
    value,
    centered,
    autoFocus = false,
    scrollToSelected = true,
    topOffset,
    onChange,
    onToggle,
    ...restProps
  } = props;

  const [opened, setOpened] = useState(autoFocus);

  const label = useMemo(() => {
    const selectedOption = options.find((option) => option && (option.value === value));

    return selectedOption ? selectedOption.label : null;
  }, [options, value]);

  const handleItemClick = useCallback((optionValue) => {
    if (onChange) {
      onChange(multiple ? [...value, optionValue] : optionValue, { target: containerRef.current });
    }
  }, [multiple, onChange, value]);

  const labelTitle = label === null ? placeholder : label;

  const IconBefore = invalid ? Icons.WarningCircle : iconBefore;

  useEffect(() => {
    if (onToggle) onToggle(opened);
  }, [opened, onToggle]);

  useLayoutEffect(() => {
    if (scrollToSelected && opened && dropdownRef.current) {
      const containerRect = dropdownRef.current.getBoundingClientRect();

      const selectedNode = dropdownRef.current.querySelector(`.${Css.selected}`);

      if (selectedNode) {
        const selectedNodeRect = selectedNode.getBoundingClientRect();

        dropdownRef.current.scrollTop = selectedNodeRect.top - containerRect.top;
      }
    }
  }, [scrollToSelected, opened, value]);

  return (
    <DropDown
      {...restProps}
      opened={opened}
      setOpened={setOpened}
      containerRef={containerRef}
      disabled={disabled}
      readOnly={readOnly}
      className={classNames(Css.select, readOnly && Css.readOnly, className)}>
      <DropDownToggle className={Css.toggle}>
        {renderLabel
          ? renderLabel(label)
          : (
            <Button
              outline
              readOnly={readOnly}
              primary={active}
              disabled={disabled}
              data-value={value}
              title={typeof labelTitle === "string" ? labelTitle : undefined}
              className={classNames(Css.button,
                {
                  [Css.invalid]: invalid,
                  [Css.valid]: valid,
                  [Css.active]: active,
                  [Css.withCaret]: useCaret,
                  [Css.withIconBefore]: IconBefore,
                  [Css.active]: active
                },
                buttonClassName)}>
              {IconBefore && <div className={Css.iconBefore}><IconBefore /></div>}
              {label === null
                ? <span className={`${Css.label} ${Css.placeholder}`}>{placeholder}</span>
                : <span className={Css.label}>{label}</span>}
              {useCaret && !readOnly && <DropDownCaret className={Css.caret} />}
            </Button>
          )}
      </DropDownToggle>
      <DropDownContent
        portal={portal}
        modal={modal}
        title={placeholder || title}
        centered={centered}
        topOffset={topOffset}
        dropdownRef={dropdownRef}
        className={classNames(
          Css.selectDropdownContent,
          dropDownClassName
        )}
        alignRight={alignRight}>
        {prepend}
        {groups.map((group) => {
          const groupItems = options
            .filter(Boolean)
            .filter((option) => group ? (option.group === group.value) : !option.group);

          return !!groupItems.length && (
            <Fragment key={group ? group.value : 0}>
              {!!group && !!group.label && (
                <div className={Css.groupLabel} key={group.value}>{group.label}</div>
              )}
              {groupItems.map((option, index) => {
                const key = String(option.value) + index;

                const selected = value === option.value;

                if (option.hidden) return null;

                return (
                  <DropDownMenuItem
                    key={key}
                    selected={selected}
                    title={typeof option.label === "string" ? option.label : undefined}
                    className={classNames(Css.option, selected && Css.selected)}
                    value={option.value}
                    disabled={option.disabled || (disableSelected && selected)}
                    data-value={option.value}
                    onClick={handleItemClick}>
                    <div className={Css.label}>{option.label}</div>
                  </DropDownMenuItem>
                );
              })}
            </Fragment>
          );
        })}
      </DropDownContent>
    </DropDown>
  );
};

export default React.memo(Select);
