import * as Icons from "@phosphor-icons/react";
import { memoize } from "decko";
import DataConstants from "const/DataConstants";

const {
  STATUSES: { TO_RECONCILE, NEED_REACTION, TO_REPORT, TO_REVIEW, EXPORTED, EXCLUDED, IN_PROGRESS }
} = DataConstants;

export default class TransactionsStatuses {
  @memoize
  static getStatusData([status, businessOrganization, needReactionAsUnclear, businessUser]) {
    const statusesData = {
      [TO_RECONCILE]: {
        value: TO_RECONCILE,
        canBeAssigned: true,
        iconComponent: Icons.Question,
        labelLangId: "toClassify",
        descriptionMessageId: "toClassifyTransactionsDescription"
      },
      [NEED_REACTION]: {
        value: NEED_REACTION,
        canBeAssigned: true,
        iconComponent: Icons.WarningCircle,
        labelLangId: needReactionAsUnclear ? "unclear" : ((businessUser || businessOrganization) ? "needReaction" : "askClient"),
        descriptionMessageId: needReactionAsUnclear ? "unclearTransactionsDescription" : "needReactionTransactionsDescription"
      },
      [TO_REVIEW]: {
        value: TO_REVIEW,
        canBeAssigned: false,
        iconComponent: Icons.Eye,
        labelLangId: businessUser ? "underReview" : "toReview",
        descriptionMessageId: businessUser ? "underReviewTransactionsDescription" : "toReviewTransactionsDescription"
      },
      [EXCLUDED]: {
        value: EXCLUDED,
        canBeAssigned: true,
        iconComponent: Icons.MinusCircle,
        labelLangId: "excluded",
        descriptionMessageId: "excludedTransactionsDescription"
      },
      [TO_REPORT]: {
        value: TO_REPORT,
        canBeAssigned: false,
        iconComponent: Icons.UploadSimple,
        labelLangId: "exporting",
        descriptionMessageId: "exportingTransactionsDescription"
      },
      [EXPORTED]: {
        value: EXPORTED,
        canBeAssigned: false,
        iconComponent: Icons.CheckCircle,
        labelLangId: "done",
        descriptionMessageId: "exportedTransactionsDescription"
      },
      [null]: {
        value: null,
        iconComponent: Icons.ListDashes,
        labelLangId: "all",
        descriptionMessageId: "allTransactionsDescription"
      },
      [IN_PROGRESS]: {
        value: IN_PROGRESS,
        iconComponent: Icons.Cpu,
        labelLangId: "roboticAiInProgress",
        descriptionMessageId: "inProgressTransactionsDescription"
      }
    };

    const availableStatusesData = { ...statusesData };

    delete availableStatusesData[EXCLUDED];

    return status === undefined ? availableStatusesData : statusesData[status];
  }
}
