import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";

const Onboarding = () => {
  const { uiTexts } = useSelector(getTextsData);

  return (
    <div className={Css.onboarding}>
      <div className={Css.content}>
        <Icons.Spinner />
        <span>{uiTexts.settingUp}</span>
      </div>
    </div>
  );
};

export default React.memo(Onboarding);
