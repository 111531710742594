import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import Button from "nlib/ui/Button";
import React, { useCallback } from "react";
import classNames from "classnames";

const DEFAULT_PAGE_SIZE = 20;

const DEFAULT_MAX_DISPLAYED_PAGES = 7;

const Pagination = (props) => {
  const {
    className,
    pageSize = DEFAULT_PAGE_SIZE,
    maxDisplayedPages = DEFAULT_MAX_DISPLAYED_PAGES,
    showFirstLast = true,
    disabled,
    onChange
  } = props;

  const page = props.page || 1;

  const count = props.count || 1;

  const pageCount = (count && pageSize) ? Math.ceil(count / pageSize) : 0;

  let endPage = 1 + maxDisplayedPages;

  let startPage = 1;

  // eslint-disable-next-line no-magic-numbers
  const halfOfMaxDisplayedPages = maxDisplayedPages / 2;

  if (page > halfOfMaxDisplayedPages) {
    startPage = Math.ceil(page - halfOfMaxDisplayedPages);
    endPage = Math.ceil(page + halfOfMaxDisplayedPages);
    if (endPage >= pageCount) {
      endPage = 1 + pageCount;
      startPage = 1 + pageCount - maxDisplayedPages;
      if (startPage < 1) startPage = 1;
    }
  } else if (pageCount < maxDisplayedPages) {
    endPage = 1 + pageCount;
    startPage = 1;
  }

  const handleItemClick = useCallback((event, name) => {
    onChange(name);
  }, [onChange]);

  return (
    <div className={classNames(Css.pagination, className)}>
      <div className={Css.pages}>
        {showFirstLast && (
          <Button
            light
            className={Css.button}
            value={1}
            disabled={disabled || page === 1}
            onClick={handleItemClick}>
            <Icons.CaretDoubleLeft />
          </Button>
        )}
        <Button
          light
          className={Css.button}
          value={page - 1}
          disabled={disabled || page === 1}
          onClick={handleItemClick}>
          <Icons.CaretLeft />
        </Button>
        {[...new Array(endPage - startPage)].map((item, index) => {
          const currentPage = index + startPage;

          return (
            <Button
              className={Css.button}
              key={currentPage}
              value={currentPage}
              primary={page === currentPage}
              light={page !== currentPage}
              disabled={disabled || page === currentPage}
              onClick={handleItemClick}>
              {currentPage}
            </Button>
          );
        })}
        <Button
          light
          className={Css.button}
          value={page + 1}
          disabled={disabled || page === pageCount}
          onClick={handleItemClick}>
          <Icons.CaretRight />
        </Button>
        {showFirstLast && (
          <Button
            light
            className={Css.button}
            value={pageCount}
            disabled={disabled || page === pageCount}
            onClick={handleItemClick}>
            <Icons.CaretDoubleRight />
          </Button>
        )}
      </div>
    </div>
  );
};

export default React.memo(Pagination);
