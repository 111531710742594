import Css from "./style.module.scss";

import React, { useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";

const DragAndDropArea = (props) => {
  const {
    className,
    accept,
    multiple,
    disabled,
    children,
    onChange,
    onDrop = onChange,
    ...restProps
  } = props;

  const inputRef = useRef();

  const [dragOver, setDragOver] = useState(false);

  const [droppedFiles, setDroppedFiles] = useState({ accepted: [], rejected: [] });

  const acceptFilesString = useMemo(() => {
    return accept ? accept.join(",") : undefined;
  }, [accept]);

  const handleClick = useCallback(() => {
    if (disabled) return;
    inputRef.current.value = null;
    inputRef.current.click();
  }, [disabled]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  }, []);

  const handleDragEnter = useCallback(() => {
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setDragOver(false);
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);

    if (disabled || !onDrop) return;

    const files = [...event.dataTransfer.files];

    const accepted = accept
      ? files.filter((file) => accept.some((ext) => file.name.endsWith(ext)))
      : files;

    const rejected = accept
      ? files.filter((file) => !accepted.includes(file))
      : [];

    setDroppedFiles({ accepted, rejected });

    if (onDrop) onDrop(accepted, rejected, event);
  }, [accept, disabled, onDrop]);

  const handleInputFileChange = useCallback((event) => {
    if (disabled || !onDrop) return;

    setDroppedFiles({ accepted: [...event.target.files], rejected: [] });
    onDrop([...event.target.files], [], event);
  }, [disabled, onDrop]);

  return (
    <div
      {...restProps}
      disabled={disabled}
      className={classNames(
        Css.dragAndDropArea,
        {
          [Css.dragOver]: dragOver,
          [Css.error]: droppedFiles.rejected.length
        },
        className
      )}
      onDragEnter={handleDragEnter}
      onClick={handleClick}>
      <input
        type="file"
        ref={inputRef}
        multiple={multiple}
        className={Css.fileInput}
        accept={acceptFilesString}
        onChange={handleInputFileChange} />
      {children && children({ dragOver: !disabled && dragOver, ...droppedFiles })}
      <div
        className={Css.overlay}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop} />
    </div>
  );
};

export default React.memo(DragAndDropArea);
