import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import DropDownContext from "nlib/ui/DropDown/DropDownContext";
import React, { useContext } from "react";
import classNames from "classnames";

const DropDownCaret = ({ className }) => {
  const { opened } = useContext(DropDownContext);

  return (
    <div className={classNames("caret", Css.dropDownCaret, opened && Css.opened, className)}>
      <Icons.CaretDown />
    </div>
  );
};

export default React.memo(DropDownCaret);
