import React, { createContext, useCallback, useMemo } from "react";

const RadioGroupContext = createContext();

const RadioGroup = ({ name = "", checked, onChange, children }) => {
  const handleChange = useCallback((value, event) => {
    if (onChange) onChange(value, event);
  }, [onChange]);

  const context = useMemo(() => {
    return { name, checked, onChange: handleChange };
  }, [handleChange, name, checked]);

  return (
    <RadioGroupContext.Provider value={context}>
      {children}
    </RadioGroupContext.Provider>
  );
};

export { RadioGroupContext };

export default React.memo(RadioGroup);
