import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import googleDrive from "assets/googleDrive.svg";

import * as Icons from "@phosphor-icons/react";
import { Button, Modal } from "nlib/ui";
import { checkOrganizationsFetching, getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useInterval } from "hooks";
import Constants from "const/Constants";
import GoogleDrivePicker from "nlib/common/GoogleDrivePicker";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import moment from "moment";

const GoogleDriveIcon = () => <img className={Css.googleDriveIcon} src={googleDrive} />;

const SetupBackupWindow = ({ onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const fetchingData = useSelector(checkOrganizationsFetching);

  const {
    backupInfo: {
      accountConnected = false,
      accountEmail = "",
      backupFolderId = "",
      backupFolderName = "",
      lastBackupAt = ""
    } = {}
  } = useSelector(getActiveOrganization);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleGoogleDriveSignIn = useCallback(async(authResult) => {
    if (authResult.email === accountEmail) {
      if (backupFolderId) {
        await dispatch(OrganizationsActions.setBackupInfo({ folderId: backupFolderId, folderName: backupFolderName }));
        await dispatch(OrganizationsActions.fetchOrganizationsList());
      }
    } else if (backupFolderId) {
      await dispatch(OrganizationsActions.deleteBackupInfo());
      await dispatch(OrganizationsActions.fetchOrganizationsList());
    }
  }, [accountEmail, backupFolderId, backupFolderName, dispatch]);

  const handleGoogleDrivePickerFilesPicked = useCallback(async(files) => {
    if (files.length) {
      const [{ id: folderId, name: folderName }] = files;

      await dispatch(OrganizationsActions.setBackupInfo({ folderId, folderName }));
      await dispatch(OrganizationsActions.fetchOrganizationsList());
    }
  }, [dispatch]);

  const handleDisconnectButtonClick = useCallback(async() => {
    await dispatch(OrganizationsActions.deleteBackupInfo());
    await dispatch(OrganizationsActions.fetchOrganizationsList());
    onClose();
  }, [onClose, dispatch]);

  useInterval(
    () => { dispatch(OrganizationsActions.fetchOrganizationsList(true)); },
    Constants.DATA_LIST_UPDATE_INTERVAL
  );

  useEffect(() => {
    dispatch(OrganizationsActions.fetchOrganizationsList());
  }, [dispatch]);

  return (
    <Modal
      title={uiTexts.setupGoogleDriveBackup}
      dialogClassName={classNames(Css.setupBackupWindow, accountConnected && Css.accountConnected)}
      iconComponent={GoogleDriveIcon}
      onClose={handleClose}>
      <div>
        <Icons.Info />
        <span>{messages.setupBackupWindowDescription}</span>
      </div>
      {accountEmail && (
        <div className={Css.connectionInfo}>
          <div className={Css.row}>
            <Icons.Activity />
            <span>
              <b>{`${uiTexts.connectionStatus}: `}</b>
              <span className={accountConnected ? CommonCss.positiveText : CommonCss.negativeText}>
                {(accountConnected ? uiTexts.connected : uiTexts.disconnected).toUpperCase()}
              </span>
            </span>
          </div>
          <div className={Css.row}>
            <Icons.User />
            <span><b>{`${uiTexts.connectedAccount}: `}</b><span title={accountEmail}>{accountEmail}</span></span>
          </div>
          <div className={Css.row}>
            <Icons.Folder />
            <span><b>{`${uiTexts.connectedFolder}: `}</b><span title={backupFolderName}>{backupFolderName}</span></span>
          </div>
          <div className={Css.row}>
            <Icons.Clock />
            <span>
              <b>{`${uiTexts.lastBackup}: `}</b>
              <span>
                {lastBackupAt
                  ? moment.duration(moment.utc(lastBackupAt).diff(moment.utc())).humanize(true)
                  : uiTexts.inProgress}
              </span>
            </span>
          </div>
        </div>
      )}
      <div className={Css.buttonsContainer}>
        <GoogleDrivePicker
          offlineAccess
          onlyFolders
          onlyOneSelection
          onlySignIn={accountEmail && !accountConnected}
          className={Css.googleDrivePicker}
          text={accountEmail
            ? (accountConnected ? uiTexts.changeFolder : uiTexts.signInWithGoogle)
            : uiTexts.selectGoogleDriveFolder}
          disabled={fetchingData}
          onSignIn={handleGoogleDriveSignIn}
          onFilesPicked={handleGoogleDrivePickerFilesPicked} />
        {accountConnected && (
          <Button
            danger large
            disabled={fetchingData}
            icon={Icons.X}
            onClick={handleDisconnectButtonClick}>{uiTexts.disconnect}</Button>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(SetupBackupWindow);
