import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DropDown, { DropDownContent, DropDownMenuItem } from "../DropDown";
import Item from "./lib/Item";
import React, { Fragment, useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";

const MultiSelect = (props) => {
  const [stateValue, setStateValue] = useState("");

  const {
    portal,
    className,
    disabled,
    invalid,
    value,
    disableSelected,
    inputValue = stateValue,
    setInputValue = setStateValue,
    dropDownClassName,
    options,
    renderLabel,
    groups = [null],
    placeholder,
    input: InputComponent = "input",
    alignRight,
    onChange,
    onFocus,
    onBlur
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const inputRef = useRef();

  const [focused, setFocused] = useState(false);

  const [opened, setOpened] = useState(false);

  const [removingMode, setRemovingMode] = useState(false);

  const optionsFiltered = useMemo(() => {
    const searchStr = inputValue.trim().toLocaleLowerCase();

    return options.filter((item) => (!searchStr || item.label.toLocaleLowerCase().includes(searchStr)));
  }, [inputValue, options]);

  const handleContentClick = useCallback(({ target, currentTarget }) => {
    if (target === currentTarget && !disabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const onRemove = useCallback((optionValue) => {
    if (onChange && !disabled) {
      setRemovingMode(false);
      onChange(value.filter((item) => item !== optionValue));
    }
  }, [disabled, onChange, value]);

  const handleInputChange = useCallback((event) => {
    setRemovingMode(false);
    setInputValue(event.target.value);
  }, [setInputValue]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === Constants.KEY_NAMES.BACKSPACE && !inputValue) {
      if (removingMode) {
        onChange(value && value.slice(0, -1));
      } else {
        setRemovingMode(true);
      }
    }
  }, [onChange, removingMode, value, inputValue]);

  const handleFocus = useCallback((event) => {
    if (onFocus) onFocus(event);
    setFocused(true);
    setOpened(true);
  }, [onFocus]);

  const handleBlur = useCallback((event) => {
    if (onBlur) onBlur(event);
    setFocused(false);
  }, [onBlur]);

  const handleItemClick = useCallback((optionValue) => {
    if (onChange) {
      onChange(value.includes(optionValue)
        ? value.filter((item) => item !== optionValue)
        : [...value, optionValue]);
    }
    setInputValue("");
    setRemovingMode(false);
  }, [onChange, value, setInputValue]);

  return (
    <div
      disabled={disabled}
      className={classNames(
        Css.tagsInput,
        disabled && Css.disabled,
        focused && Css.focused,
        invalid && Css.invalid,
        className
      )}>
      <DropDown
        className={Css.dropdown}
        opened={opened}
        setOpened={setOpened}>
        <div className={Css.content} onClick={handleContentClick}>
          {invalid && <Icons.WarningCircle className={Css.iconBefore} />}
          {options
            .filter((item) => value.includes(item.value))
            .map((item, index) => (
              <Item
                key={item.value}
                value={item.value}
                label={item.label}
                highlight={removingMode && index === (value.length - 1)}
                disabled={disabled}
                onRemove={onRemove} />
            ))}
          <InputComponent
            ref={inputRef}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur} />
        </div>
        {!!options.length && (
          <DropDownContent
            portal={portal}
            className={classNames(
              Css.selectDropdownContent,
              dropDownClassName
            )}
            alignRight={alignRight}>
            <div className={Css.scroll}>
              {optionsFiltered.length
                ? groups.map((group) => {
                  const groupItems = optionsFiltered
                    .filter((option) => option && (group ? (option.group === group.value) : !option.group));

                  return !!groupItems.length && (
                    <Fragment key={group ? group.value : 0}>
                      {!!group && (
                        <div className={Css.groupLabel} key={group.value}>{group.label}</div>
                      )}
                      {groupItems.map((option, index) => {
                        const key = String(option.value) + index;

                        const selected = value.includes(option.value);

                        return (
                          <DropDownMenuItem
                            key={key}
                            title={option.label}
                            className={classNames(Css.option, selected && Css.selected)}
                            value={option.value}
                            selected={selected}
                            disabled={option.disabled || (disableSelected && selected)}
                            onClick={handleItemClick}>
                            <div className={Css.label}>
                              {renderLabel ? renderLabel(option) : option.label}
                            </div>
                          </DropDownMenuItem>
                        );
                      })}
                    </Fragment>
                  );
                })
                : <div className={Css.placeholder}>{uiTexts.nothingFound}</div>}
            </div>
          </DropDownContent>
        )}
      </DropDown>
    </div>
  );
};

export default React.memo(MultiSelect);
