import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useCallback } from "react";
import classNames from "classnames";

const Checkbox = (props) => {
  const {
    readOnly,
    disabled,
    value = false,
    checked = value,
    indeterminate = false,
    toggle,
    alignRight,
    className,
    labelClassName,
    checkedLabel = null,
    uncheckedLabel = null,
    label = checked ? checkedLabel : uncheckedLabel,
    children = label,
    onChange,
    onClick,
    ...rest
  } = props;

  const handleClick = useCallback((event) => {
    if (disabled || readOnly) return;
    if (onClick) onClick(event);
    if (onChange) onChange(!checked, event);
  }, [disabled, checked, readOnly, onClick, onChange]);

  const Icon = checked ? Icons.Check : Icons.Minus;

  return (
    <div
      {...rest}
      className={classNames(
        Css.checkbox,
        toggle && Css.toggle,
        alignRight && Css.alignRight,
        readOnly && Css.readOnly,
        className
      )}
      disabled={disabled}
      onClick={handleClick}>
      <div
        className={classNames(Css.box, {
          [Css.checked]: checked,
          [Css.indeterminate]: indeterminate
        })}>
        <Icon weight="bold" />
      </div>
      {(children !== null) && (
        <div className={classNames(Css.label, labelClassName)}>{children}</div>
      )}
    </div>
  );
};

export default React.memo(Checkbox);
