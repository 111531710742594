import Css from "nlib/ui/Table/style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useCallback, useContext } from "react";
import TableCell from "nlib/ui/Table/lib/TableCell";
import TableContext from "nlib/ui/Table/TableContext";
import classNames from "classnames";

const SORT_ORDER_ASC = "asc";

const SORT_ORDER_DESC = "desc";

const TableHead = ({ children, className, accessor, show = true, ...restProps }) => {
  const { sortBy, sortOrder, onSortChange } = useContext(TableContext);

  const handleClick = useCallback(() => {
    if (!accessor || !onSortChange) return;
    if (accessor !== sortBy) {
      onSortChange({ sortBy: accessor, sortOrder: SORT_ORDER_ASC });
    } else if (sortOrder === SORT_ORDER_ASC) {
      onSortChange({ sortBy: accessor, sortOrder: SORT_ORDER_DESC });
    } else {
      onSortChange({ sortBy: null, sortOrder: null });
    }
  }, [accessor, sortBy, sortOrder, onSortChange]);

  if (!show) return null;

  return (
    <TableCell
      {...restProps}
      data-table-head
      className={classNames(Css.tableHead, {
        [Css.sortable]: !!accessor,
        [Css.active]: sortBy && sortBy === accessor
      }, className)}
      onClick={handleClick}>
      <div className={Css.title} title={typeof children === "string" ? children : undefined}>{children}</div>
      {!!accessor && (sortBy === accessor) && (
        <div className={Css.sortIcons}>
          {sortOrder === SORT_ORDER_DESC
            ? <Icons.CaretUp weight="bold" />
            : <Icons.CaretDown weight="bold" />}
        </div>
      )}
    </TableCell>
  );
};

export default React.memo(TableHead);
