import Css from "./style.module.scss";

import appStoreButton from "assets/appStoreButton.svg";
import googlePlayButton from "assets/googlePlayButton.svg";

import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import Modal from "nlib/ui/Modal";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";

const DownloadAppModal = () => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const activeOrganization = useSelector(getActiveOrganization);

  const [modalShown, setModalShown] = useState(!Utils.storageValue(Constants.LS_KEYS.DOWNLOAD_APP_PROMPT));

  const platform = useMemo(() => {
    return !Utils.checkIsNativePlatform() && Utils.getMobilePlatformType();
  }, []);

  const imageToDisplay = useMemo(() => {
    if (!platform) return null;

    return platform === Constants.PLATFORM_TYPES.IOS ? appStoreButton : googlePlayButton;
  }, [platform]);

  const handleButtonClick = useCallback(() => {
    const { IOS_APP_URL, ANDROID_APP_URL } = Utils.getProcessEnvVars();

    window.location.href = platform === Constants.PLATFORM_TYPES.IOS ? IOS_APP_URL : ANDROID_APP_URL;
  }, [platform]);

  const handleClose = useCallback(() => {
    setModalShown(false);
    Utils.storageValue(Constants.LS_KEYS.DOWNLOAD_APP_PROMPT, true);
  }, []);

  if (!!activeOrganization?.whiteLabel || !userData || !modalShown || !imageToDisplay) return null;

  return (
    <Modal
      className={Css.downloadAppModal}
      iconComponent={null}
      title={uiTexts.downloadApp}
      onClose={handleClose}>
      <div className={Css.text}>{messages.installAppWindow}</div>
      <div className={Css.image} onClick={handleButtonClick}>
        <img src={imageToDisplay} />
      </div>
    </Modal>
  );
};

export default React.memo(DownloadAppModal);
