import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Pagination } from "nlib/ui";
import { checkMatchesFetching, getGlobalStats, getMatchesData, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useMainApi } from "hooks";
import Badge from "nlib/ui/Badge";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import Filters from "./lib/Filters";
import MainApiRoutes from "const/MainApiRoutes";
import Modal from "nlib/ui/Modal";
import NoDataContent from "nlib/common/NoDataContent";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import RestApi from "api/RestApi";
import Table, { TableHead, TableRow } from "nlib/ui/Table";
import Tabs, { Tab } from "nlib/ui/Tabs";
import TransactionsTableRow from "./lib/TransactionsTableRow";

const { TABLE_PAGE_SIZE } = Constants;

const { STATUSES: { TO_RECONCILE, NEED_REACTION, TO_REVIEW } } = DataConstants;

const { BUSINESSES, TRANSACTIONS, STATS } = MainApiRoutes;

const MIN_ROWS_COUNT_TO_ENABLE_SEARCH = 7;

const TRANSACTIONS_PAIR_FILTERS = {
  MATCHING: "matching",
  ALL: "all"
};

const { MATCHING, ALL } = TRANSACTIONS_PAIR_FILTERS;

const STATUSES = [TO_RECONCILE, NEED_REACTION, TO_REVIEW];

const PairTransactionsWindow = ({ documentData, onClose, ...restProps }) => {
  const { matchedTransactions = 0, paidTransactions = [] } = documentData;

  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const fetchingMatches = useSelector(checkMatchesFetching);

  const globalStats = useSelector(getGlobalStats);

  const matchesData = useSelector(getMatchesData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const [modalInited, setModalInited] = useState(false);

  const [filters, setFilters] = useState({});

  const [sortingProps, setSortingProps] = useState({});

  const [page, setPage] = useState(1);

  const [currentTab, setCurrentTab] = useState(!paidTransactions.length && matchedTransactions ? MATCHING : ALL);

  const { id: documentId } = documentData;

  const tabAll = currentTab === ALL;

  const [{ results: transactionsData }, fetchingTransactions] = useMainApi({
    method: RestApi.REQUEST_METHODS.POST,
    initialData: { results: null },
    fetchCondition: (
      !filters.text || filters.text.length >= Constants.SEARCH_TEXT_MIN_LENGTH
    ),
    parameters: [
      `${BUSINESSES}/${selectedBusinessId}${TRANSACTIONS}`,
      null,
      {
        ...filters,
        text: filters.text || null,
        status: STATUSES,
        offset: (page - 1) * TABLE_PAGE_SIZE,
        limit: TABLE_PAGE_SIZE,
        sortings: sortingProps
      }
    ],
    dependencies: [filters, page, sortingProps]
  });

  const [filteredTransactionsStats, fetchingFilteredTransactionsStats] = useMainApi({
    method: RestApi.REQUEST_METHODS.GET,
    initialData: {},
    fetchCondition: (
      !filters.text || filters.text.length >= Constants.SEARCH_TEXT_MIN_LENGTH
    ),
    parameters: [
      `${BUSINESSES}/${selectedBusinessId}${TRANSACTIONS}${STATS}`,
      { ...filters, text: filters.text || null }
    ],
    dependencies: [filters]
  });

  const tableData = useMemo(() => {
    if (tabAll) {
      return transactionsData ? [
        ...paidTransactions,
        ...transactionsData.filter(({ id }) => paidTransactions.every((transaction) => transaction.id !== id))
      ] : [];
    }

    return matchesData.map(({ transaction }) => transaction);
  }, [matchesData, paidTransactions, tabAll, transactionsData]);

  const transactionsCount = useMemo(() => {
    return STATUSES.reduce((result, status) => {
      return result + (globalStats.transactions[status] || { count: 0 }).count;
    }, 0);
  }, [globalStats.transactions]);

  const filteredTransactionsCount = useMemo(() => {
    return STATUSES.reduce((result, status) => {
      return result + (filteredTransactionsStats[status] || { count: 0 }).count;
    }, 0);
  }, [filteredTransactionsStats]);

  const handleChangeFilters = useCallback((value) => {
    setFilters((prev) => ({ ...prev, ...value }));
    setPage(1);
  }, []);

  const handleTableSortChange = useCallback((value) => {
    setSortingProps({ id: value.sortBy, desc: value.sortOrder === "desc" });
  }, []);

  const handlePageChange = useCallback((value) => {
    setPage(value);
  }, []);

  useLayoutEffect(() => {
    dispatch(BusinessesActions.fetchDataMatchesList(null, documentId, true));
  }, [dispatch, documentId]);

  const fetchingData = fetchingMatches || fetchingTransactions || fetchingFilteredTransactionsStats;

  const itemsCount = tabAll ? filteredTransactionsCount : tableData.length;

  useEffect(() => {
    if (!fetchingData) setModalInited(true);
  }, [fetchingData]);

  return (
    <Modal
      {...restProps}
      iconComponent={Icons.LinkSimpleHorizontal}
      title={uiTexts.pairedTransactions}
      className={Css.pairTransactionsWindow}
      dialogClassName={Css.modalDialog}
      onClose={onClose}>
      <Tabs className={Css.tabs} current={currentTab} onChange={setCurrentTab}>
        <Tab className={Css.tab} value={MATCHING}>
          <span>{uiTexts.matching}</span>
          <Badge
            counter inverted
            theme={matchedTransactions ? "alternative" : ""}
            value={matchedTransactions} />
        </Tab>
        <Tab className={Css.tab} value={ALL}>
          <span>{uiTexts.allTransactions}</span>
          <Badge
            counter inverted
            theme={transactionsCount ? "primary" : ""}
            value={transactionsCount} />
        </Tab>
      </Tabs>
      <div className={Css.contentWrap}>
        {tabAll && transactionsCount > MIN_ROWS_COUNT_TO_ENABLE_SEARCH && (
          <Filters
            disabled={fetchingData}
            filters={filters}
            className={!modalInited && Css.hidden}
            onChange={handleChangeFilters} />
        )}
        {tableData.length
          ? (
            <>
              <Table
                theme="flat"
                sortBy={sortingProps.id}
                sortOrder={sortingProps.desc ? "desc" : "asc"}
                onSortChange={handleTableSortChange}
                className={Css.table}>
                <TableRow>
                  <TableHead className={Css.statusCell}>{uiTexts.status}</TableHead>
                  <TableHead className={Css.accountCell}>{uiTexts.account}</TableHead>
                  <TableHead className={Css.amountCell}>{uiTexts.total}</TableHead>
                  <TableHead className={Css.addressCell}>{uiTexts.payee}</TableHead>
                  <TableHead className={Css.timestampCell}>{uiTexts.date}</TableHead>
                  <TableHead className={Css.descriptionCell}>{uiTexts.description}</TableHead>
                  <TableHead className={Css.actionsCell}>{uiTexts.actions}</TableHead>
                </TableRow>
                {tableData.map((transaction) => (
                  <TransactionsTableRow
                    key={transaction.id}
                    transaction={transaction}
                    documentData={documentData}
                    onClose={onClose} />
                ))}
              </Table>
              {(itemsCount > TABLE_PAGE_SIZE) && (
                <Pagination
                  className={Css.pagination}
                  count={itemsCount}
                  page={page}
                  pageSize={TABLE_PAGE_SIZE}
                  disabled={fetchingData}
                  onChange={handlePageChange} />
              )}
            </>
          )
          : (fetchingData
            ? <Preloader />
            : (
              <NoDataContent
                icon={Icons.CreditCard}
                title={uiTexts.noTransactionsFound} />
            ))}
      </div>
    </Modal>
  );
};

export default React.memo(PairTransactionsWindow);
