import Css from "../../style.module.scss";

import { FormElement } from "nlib/common/Form";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Checkbox from "nlib/ui/Checkbox";
import FormLabel from "nlib/common/FormLabel";
import IntegrationServices from "const/IntegrationServices";
import React from "react";

const OcrAiTab = ({ rpaMode, integrationService }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const quickBooksDesktopBusiness = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

  return (
    <>
      <div className={Css.block}>
        <FormLabel>{uiTexts.documents}</FormLabel>
        {!rpaMode && !quickBooksDesktopBusiness && (
          <div className={Css.row}>
            <FormElement
              toggle
              name="advancedDocumentsWorkflow"
              element={Checkbox}>
              <span>{messages.advancedDocumentWorkflowToggleLabelText}</span>
            </FormElement>
          </div>
        )}
        <div className={Css.row}>
          <FormElement
            toggle
            name="recognizeDocumentLineItems"
            element={Checkbox}>
            <span>{messages.recognizeLineItemsToggleLabelText}</span>
          </FormElement>
        </div>
        {!rpaMode && (
          <div className={Css.row}>
            <FormElement
              toggle
              name="allowAutoCreatePayee"
              element={Checkbox}>
              <span>{messages.autoCreatePayeeToggleLabelText}</span>
            </FormElement>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(OcrAiTab);
