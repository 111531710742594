import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AwaitingButton from "../AwaitingButton";
import DropDown, { DropDownCaret, DropDownContent, DropDownMenuItem, DropDownToggle } from "nlib/ui/DropDown";
import MenuItem from "./lib/MenuItem";
import React from "react";
import classNames from "classnames";

const ActionCellContent = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    toggleIcon: ToggleIcon = Icons.DotsThreeVertical,
    toggleContent = uiTexts.actions,
    menuItems,
    className,
    onClick,
    ...restProps
  } = props;

  const [firstMenuItem, ...restMenuItems] = menuItems;

  const [secondMenuItem] = restMenuItems;

  return (
    <div className={classNames(Css.actionCellContent, className)} {...restProps}>
      {!!firstMenuItem && (
        <MenuItem
          light
          className={Css.button}
          component={AwaitingButton}
          data={firstMenuItem}
          onClick={onClick} />
      )}
      {!!restMenuItems.length && (
        restMenuItems.length === 1
          ? (
            <MenuItem
              light
              className={Css.button}
              component={AwaitingButton}
              data={secondMenuItem}
              onClick={onClick} />
          )
          : (
            <DropDown className={Css.dropdown}>
              <DropDownToggle className={Css.toggle}>
                <Button light className={Css.button}>
                  {!!ToggleIcon && <ToggleIcon />}
                  <span>{toggleContent}</span>
                  <DropDownCaret />
                </Button>
              </DropDownToggle>
              <DropDownContent className={Css.dropdownContent}>
                {restMenuItems.map((item) => {
                  if (!item) return null;

                  return (
                    <MenuItem
                      key={item.action}
                      className={Css.dropdownMenuItem}
                      component={DropDownMenuItem}
                      data={item}
                      onClick={onClick} />
                  );
                })}
              </DropDownContent>
            </DropDown>
          )
      )}
    </div>
  );
};

export default React.memo(ActionCellContent);
