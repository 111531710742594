import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DropDown, { DropDownContent, DropDownMenuItem } from "../DropDown";
import Item from "./lib/Item";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";

const KEY_NAMES = [Constants.KEY_NAMES.ENTER, Constants.KEY_NAMES.COMMA];

const TagsInput = (props) => {
  const [stateValue, setStateValue] = useState("");

  const {
    className,
    disabled,
    invalid,
    value,
    portal,
    inputValue = stateValue,
    setInputValue = setStateValue,
    autoCompleteData = [],
    placeholder,
    dropDownClassName,
    groups = [null],
    splitKeys = KEY_NAMES,
    input: InputComponent = "input",
    renderLabel,
    onChange,
    onFocus,
    onBlur
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const inputRef = useRef();

  const [focused, setFocused] = useState(false);

  const [removingMode, setRemovingMode] = useState(false);

  const [opened, setOpened] = useState(false);

  const autoCompleteDataFiltered = useMemo(() => {
    if (!autoCompleteData.length) return null;

    const searchString = inputValue.trim().toLocaleLowerCase();

    if (!searchString) return autoCompleteData;

    return autoCompleteData.filter((item) => (item.label || "").toLocaleLowerCase().includes(searchString));
  }, [autoCompleteData, inputValue]);

  const completeInput = useCallback(() => {
    setRemovingMode(false);

    const inputValues = inputValue.trim().replace(/\s*,\s*/g, ",").replace(/\s+/g, " ").split(",").filter(Boolean);

    if (inputValues.length && onChange) {
      const uniqueValues = inputValues.filter((item) => (!value || !value.includes(item)));

      onChange(
        (Array.isArray(value) ? [...value, ...uniqueValues] : uniqueValues).filter(Boolean)
      );

      setInputValue("");
    }
  }, [inputValue, onChange, setInputValue, value]);

  const handleContentClick = useCallback(({ target, currentTarget }) => {
    if (target === currentTarget && !disabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const onRemove = useCallback((index) => {
    if (onChange && !disabled) {
      setRemovingMode(false);
      onChange(value.filter((tag, idx) => idx !== index));
    }
  }, [disabled, onChange, value]);

  const handleInputChange = useCallback((event) => {
    setRemovingMode(false);
    setInputValue(event.target.value);
  }, [setInputValue]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === Constants.KEY_NAMES.BACKSPACE && !inputValue) {
      if (removingMode) {
        onChange(value && value.slice(0, -1));
      } else {
        setRemovingMode(true);
      }
    } else if (splitKeys && splitKeys.includes(event.key)) {
      event.preventDefault();
      completeInput();
    }
  }, [completeInput, onChange, removingMode, splitKeys, value, inputValue]);

  const handleFocus = useCallback((event) => {
    if (onFocus) onFocus(event);
    setOpened(!!autoCompleteData.length);
    setFocused(true);
  }, [autoCompleteData.length, onFocus]);

  const handleBlur = useCallback((event) => {
    setFocused(false);
    if (onBlur) onBlur(event);
    if (!autoCompleteData.length) {
      completeInput();
    }
  }, [autoCompleteData.length, completeInput, onBlur]);

  const handleItemClick = useCallback((itemValue) => {
    setInputValue("");
    onChange(value.includes(itemValue)
      ? value.filter((item) => item !== itemValue)
      : [...value, itemValue]);
  }, [onChange, value, setInputValue]);

  useEffect(() => {
    if (autoCompleteDataFiltered && autoCompleteDataFiltered.includes(inputValue)) completeInput();
  }, [autoCompleteDataFiltered, inputValue, completeInput, setInputValue]);

  return (
    <div
      disabled={disabled}
      className={classNames(
        Css.tagsInput,
        disabled && Css.disabled,
        focused && Css.focused,
        invalid && Css.invalid,
        className
      )}>
      <DropDown
        className={Css.dropdown}
        opened={opened}
        setOpened={setOpened}>
        <div className={Css.content} onClick={handleContentClick}>
          {invalid && <Icons.WarningCircle className={Css.iconBefore} />}
          {value.map((tag, index) => (
            <Item
              key={tag.key || tag}
              value={tag}
              index={index}
              highlight={removingMode && index === (value.length - 1)}
              disabled={disabled}
              onRemove={onRemove} />
          ))}
          <InputComponent
            ref={inputRef}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur} />
        </div>
        {!!autoCompleteDataFiltered?.length && (
          <DropDownContent
            portal={portal}
            className={classNames(
              Css.selectDropdownContent,
              dropDownClassName
            )}>
            <div className={Css.scroll}>
              {!!autoCompleteData.length && (
                autoCompleteDataFiltered.length
                  ? groups.map((group) => {
                    const groupItems = autoCompleteDataFiltered
                      .filter((option) => option && (group ? (option.group === group.value) : !option.group));

                    return !!groupItems.length && (
                      <Fragment key={group ? group.value : 0}>
                        {!!group && (
                          <div className={Css.groupLabel} key={group.value}>{group.label}</div>
                        )}
                        {groupItems.map((option, index) => {
                          const key = String(option.value) + index;

                          const selected = value.includes(option.value);

                          return (
                            <DropDownMenuItem
                              key={key}
                              title={option.label}
                              className={Css.option}
                              value={option.value}
                              selected={selected}
                              disabled={option.disabled}
                              onClick={handleItemClick}>
                              <div className={Css.label}>
                                {renderLabel ? renderLabel(option) : option.label}
                              </div>
                            </DropDownMenuItem>
                          );
                        })}
                      </Fragment>
                    );
                  })
                  : <div className={Css.placeholder}>{uiTexts.nothingFound}</div>
              )}
            </div>
          </DropDownContent>
        )}

      </DropDown>
    </div>
  );
};

export default React.memo(TagsInput);
