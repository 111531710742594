export default class Businesses {
  static checkIsOnboarding(extraData, settings = {}) {
    const {
      uncategorizedRpaEnabled,
      reconciliationRpaEnabled,
      lastGptAiCategorizedAt,
      lastEntitiesAutoSetAt
    } = extraData || {};

    return (uncategorizedRpaEnabled === undefined && reconciliationRpaEnabled === undefined
      ? !lastEntitiesAutoSetAt
      : (
        !lastGptAiCategorizedAt
        || uncategorizedRpaEnabled === false
        || reconciliationRpaEnabled === false
        || settings.rpaAutoAskClient === undefined
      ));
  }

  static checkIsRpaMode(extraData) {
    const { uncategorizedRpaEnabled, reconciliationRpaEnabled } = extraData || {};

    return uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined;
  }
}
