import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const Counter = ({ className, value, children, negative, ...restProps }) => (
  <span
    {...restProps}
    className={classNames(
      Css.counter,
      negative && Css.negative,
      className
    )}>
    {children || value}
  </span>
);

export default React.memo(Counter);
