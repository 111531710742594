import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import {
  checkContactsFetching,
  getContactsSortedData,
  getContactsSubTypeCustomerSortedData,
  getContactsSubTypeVendorsSortedData
} from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import NewContactWidget from "./lib/NewContactWidget";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const { CONTACT_SUB_TYPES: { VENDOR, CUSTOMER } } = DataConstants;

const SelectContactInput = (props) => {
  const [openedState, setOpenedState] = useState(false);

  const {
    value: { name = "", id } = {},
    currency,
    validate,
    vendorId = id,
    active,
    disabled,
    highlight,
    className,
    useWidget,
    placeholder,
    probablyVendor,
    filterFunction,
    onlyVendors,
    onlyCustomers,
    withButton,
    opened = openedState,
    setOpened = setOpenedState,
    onChange,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const contactsData = useSelector(getContactsSortedData);

  const contactsSubTypeVendorData = useSelector(getContactsSubTypeVendorsSortedData);

  const contactsSubTypeCustomerData = useSelector(getContactsSubTypeCustomerSortedData);

  const contactsFetching = useSelector(checkContactsFetching);

  const [widgetOpened, setWidgetOpened] = useState(false);

  const [showAll, setShowAll] = useState(false);

  const [firstContact] = contactsData;

  const contactsListFlag = firstContact && firstContact.short;

  const contactsList = useMemo(() => {
    if (disabled) return [];

    const contacts = (!!onlyCustomers === !!onlyVendors)
      ? contactsData
      : (onlyCustomers ? contactsSubTypeCustomerData : contactsSubTypeVendorData);

    return (filterFunction ? contacts.filter(filterFunction) : contacts)
      .map((item) => ({ ...item, label: item.name }));
  }, [
    contactsData,
    contactsSubTypeCustomerData,
    contactsSubTypeVendorData,
    disabled,
    filterFunction,
    onlyCustomers,
    onlyVendors
  ]);

  const getItems = useCallback(() => {
    if (disabled) return [];

    const contacts = (!!onlyCustomers === !!onlyVendors)
      ? contactsData
      : (onlyCustomers ? contactsSubTypeCustomerData : contactsSubTypeVendorData);

    return (filterFunction ? contacts.filter(filterFunction) : contacts)
      .map((item) => ({ ...item, label: item.name }));
  }, [
    contactsData,
    contactsSubTypeCustomerData,
    contactsSubTypeVendorData,
    disabled,
    filterFunction,
    onlyCustomers,
    onlyVendors
  ]);

  const renderLabel = useCallback((item) => {
    return (
      <div className={Css.listItem} title={item.name}>
        <span className={Css.name}>
          {item.name || item.label}
        </span>
        <Badge
          className={Css.badge}
          primary={item.subType === VENDOR}
          positive={item.subType === CUSTOMER}>
          {uiTexts[item.subType] || uiTexts.contact}
        </Badge>
      </div>
    );
  }, [uiTexts]);

  const showWidget = useWidget && !contactsData.find((item) => item.name === name);

  const handleChange = useCallback((newName, event) => {
    if (onChange) onChange({ name: newName }, event);
  }, [onChange]);

  const handleAutoComplete = useCallback((item, event) => {
    const found = Utils.arrayFindById(contactsData, item.id);

    if (found) onChange(found, event);
  }, [contactsData, onChange]);

  const handleAddContact = useCallback((contact, event) => {
    if (onChange) onChange(contact, event);
  }, [onChange]);

  const handleNewContactClick = useCallback(() => {
    setWidgetOpened(true);
    setOpened(false);
  }, [setOpened]);

  const handleButtonClick = useCallback(() => {
    if (vendorId || !name) {
      setShowAll(true);
      setOpened(true);
    } else {
      setWidgetOpened(true);
      setOpened(false);
    }
  }, [name, setOpened, vendorId]);

  return (
    <div
      className={classNames(
        Css.selectContactInput,
        withButton && Css.withButton,
        contactsFetching && Css.loading,
        className
      )}>
      <NewContactWidget
        opened={widgetOpened}
        probablyVendor={probablyVendor}
        name={name}
        currency={currency}
        disabledSubTypeInput={!!(onlyCustomers || onlyVendors)}
        setOpened={setWidgetOpened}
        onAddContact={handleAddContact} />
      <AutoCompleteInput
        {...(validate ? {
          valid: !!(name && vendorId),
          invalid: !!(name && !vendorId)
        } : null)}
        {...restProps}
        iconBeforeTitle={uiTexts.payee}
        active={active && !contactsFetching}
        highlight={highlight}
        opened={opened}
        showAll={showAll}
        value={name}
        items={contactsList}
        disabled={disabled || contactsFetching}
        useCaret={!contactsListFlag}
        triggerLength={contactsListFlag ? Constants.SEARCH_TEXT_MIN_LENGTH : undefined}
        placeholder={placeholder || (contactsListFlag ? uiTexts.searchContact : uiTexts.selectContact)}
        iconBefore={active ? Icons.UserCirclePlus : Icons.AddressBook}
        getItems={getItems}
        renderLabel={renderLabel}
        setOpened={setOpened}
        onChange={handleChange}
        onAutoComplete={handleAutoComplete}
        onSetShowAll={setShowAll}>
        {showWidget && (
          <div className={Css.addNew} onClick={handleNewContactClick}>
            <Icons.UserCirclePlus />
            {name
              ? (
                <>
                  <span>{uiTexts.add}</span>
                  <span className={Css.newValue} title={name}>{name}</span>
                </>
              )
              : uiTexts.addNewContact}
          </div>
        )}
      </AutoCompleteInput>
      {withButton && (
        <Button
          outline
          success={!!vendorId}
          primary={!!name && !vendorId}
          className={Css.button}
          onClick={handleButtonClick} >
          {vendorId ? <Icons.User /> : <Icons.UserPlus />}
        </Button>
      )}
    </div>
  );
};

export default React.memo(SelectContactInput);
