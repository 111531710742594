import Css from "./style.module.scss";

import React, { useCallback } from "react";
import classNames from "classnames";

const DatePickerMonth = (props) => {
  const {
    month,
    name,
    selected,
    disabled,
    onSelect
  } = props;

  const handleClick = useCallback(() => {
    if (disabled || !onSelect) return;
    onSelect(month);
  }, [disabled, month, onSelect]);

  return (
    <div
      className={classNames(Css.datePickerMonth, {
        [Css.selected]: selected
      })}
      onClick={handleClick}>
      {name}
    </div>
  );
};

export default React.memo(DatePickerMonth);
