import Css from "./style.module.scss";

import DropDownContext from "nlib/ui/DropDown/DropDownContext";
import React, { useCallback, useContext } from "react";
import classNames from "classnames";

const DropDownToggle = ({ children, className, openedClassName, onClick, ...rest }) => {
  const { opened, disabled, readOnly, setOpened } = useContext(DropDownContext);

  const handleClick = useCallback((event) => {
    if (disabled || readOnly) return;
    setOpened(!opened);
    if (onClick) onClick(event);
  }, [opened, disabled, readOnly, setOpened, onClick]);

  return (
    <div
      {...rest}
      data-opened={opened || undefined}
      className={classNames(Css.dropDownToggle,
        opened && openedClassName,
        className)}
      disabled={disabled}
      onClick={handleClick}>
      {children}
    </div>
  );
};

export default React.memo(DropDownToggle);
