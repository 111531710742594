
import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Input from "nlib/ui/Input";
import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";

const IncludeExclude = (props) => {
  const {
    emptyPlaceholder,
    disabled,
    value = [],
    options = [],
    onChange,
    className,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [search, setSearch] = useState("");

  const filteredItems = useMemo(() => {
    const str = search.trim().toLocaleLowerCase();

    if (!str) return options;

    return options.filter((option) => option.label.toLocaleLowerCase().includes(str));
  }, [options, search]);

  const availableFilteredItems = useMemo(() => {
    return filteredItems.filter((option) => !value.includes(option.value));
  }, [filteredItems, value]);

  const selectableAvailableItems = useMemo(() => {
    return availableFilteredItems.filter((option) => !option.disabled);
  }, [availableFilteredItems]);

  const addedFilteredItems = useMemo(() => {
    return filteredItems.filter((option) => value.includes(option.value));
  }, [filteredItems, value]);

  const availableAddedItems = useMemo(() => {
    return addedFilteredItems.filter((option) => !option.disabled);
  }, [addedFilteredItems]);

  const handleListItemClick = useCallback(({ currentTarget: { dataset: { value: itemValue } } }) => {
    onChange(value.includes(itemValue) ? value.filter((item) => item !== itemValue) : [...value, itemValue]);
  }, [value, onChange]);

  const handleAddAllAvailableClick = useCallback(() => {
    onChange([...value, ...selectableAvailableItems.map((option) => option.value)]);
  }, [value, selectableAvailableItems, onChange]);

  const handleRemoveAllAddedClick = useCallback(() => {
    const addedItemsValues = availableAddedItems.map((option) => option.value);

    onChange(value.filter((item) => !addedItemsValues.includes(item)));
  }, [availableAddedItems, onChange, value]);

  return (
    <div {...restProps} className={classNames(Css.includeExclude, className)}>
      <Input
        value={search}
        disabled={disabled}
        className={Css.input}
        placeholder={uiTexts.search}
        iconBefore={Icons.MagnifyingGlass}
        onChange={setSearch} />
      <div className={Css.row}>
        <div className={Css.col}>
          <div className={Css.head}>
            <div
              disabled={disabled || !availableFilteredItems.length}
              className={Css.button}
              onClick={handleAddAllAvailableClick}>
              <Icons.PlusSquare />
              <span>{`${uiTexts.available} (${availableFilteredItems.length})`}</span>
            </div>
          </div>
          <div className={Css.content}>
            {availableFilteredItems.length
              ? availableFilteredItems.map((option) => {
                return (
                  <div
                    key={option.value}
                    data-value={option.value}
                    title={option.label}
                    disabled={disabled || option.disabled}
                    className={Css.item}
                    onClick={handleListItemClick}>
                    <Icons.PlusSquare />
                    <div className={Css.label}>{option.label}</div>
                  </div>
                );
              })
              : (!!search.trim() && !!emptyPlaceholder && (
                <div className={Css.placeholder}>{emptyPlaceholder}</div>
              ))}
          </div>
        </div>
        <div className={Css.col}>
          <div className={Css.head}>
            <div
              disabled={disabled || !addedFilteredItems.length}
              className={Css.button}
              onClick={handleRemoveAllAddedClick}>
              <Icons.MinusSquare />
              <span>{`${uiTexts.added} (${addedFilteredItems.length})`}</span>
            </div>
          </div>
          <div className={Css.content}>
            {addedFilteredItems.length
              ? addedFilteredItems.map((option) => {
                return (
                  <div
                    key={option.value}
                    data-value={option.value}
                    title={option.label}
                    disabled={disabled || option.disabled}
                    className={Css.item}
                    onClick={handleListItemClick}>
                    <Icons.MinusSquare />
                    <div className={Css.label}>{option.label}</div>
                  </div>
                );
              })
              : (!!search.trim() && !!emptyPlaceholder && (
                <div className={Css.placeholder}>{emptyPlaceholder}</div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(IncludeExclude);
