import Css from "./style.module.scss";

import FormContext from "contexts/FormContext";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const Form = React.memo((props) => {
  const {
    children,
    initialValues = {},
    validateOnChange,
    validation,
    compact,
    disabled,
    className,
    component: Component = "div",
    onSubmit,
    onChange
  } = props;

  const [state, setState] = useState({ values: initialValues, errors: {} });

  const { values, errors } = state;

  const setErrors = useCallback((newErrors) => {
    setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, ...newErrors } }));
  }, [setState]);

  const resetForm = useCallback((toInitialValues) => {
    setState(() => ({
      values: toInitialValues ? initialValues : {},
      errors: {}
    }));
  }, [setState, initialValues]);

  const validateForm = useCallback(async() => {
    if (!validation) return true;

    const formErrors = await validation(values);

    if (!formErrors || !Object.keys(formErrors).length) return true;

    setState((prevState) => ({ ...prevState, errors: formErrors }));

    return false;
  }, [validation, values, setState]);

  const setValues = useCallback((newValues) => {
    const entries = Object.entries(newValues);

    setState((prevState) => {
      return {
        ...prevState,
        values: entries.reduce((prevValues, [name, value]) => {
          return onChange
            ? onChange(name, value, prevValues)
            : { ...prevValues, [name]: value };
        }, prevState.values),
        errors: entries.reduce((prevErrors, [name]) => {
          return { ...prevErrors, [name]: null };
        }, prevState.errors)
      };
    });
  }, [onChange]);

  const handleChange = useCallback(({ name, value }) => {
    setValues({ [name]: value });
  }, [setValues]);

  const handleSubmit = useCallback(async(event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (!await validateForm() || !onSubmit) return null;

    return onSubmit({ values, extraData: event, resetForm, setErrors });
  }, [validateForm, onSubmit, values, resetForm, setErrors]);

  const hasChanges = useMemo(() => !Utils.deepEqual(initialValues, values), [initialValues, values]);

  const contextValue = useMemo(() => {
    return {
      values,
      errors,
      disabled,
      hasChanges,
      resetForm,
      setValues,
      setState,
      onChange: handleChange,
      onSubmit: handleSubmit
    };
  }, [
    values,
    errors,
    disabled,
    hasChanges,
    resetForm,
    setValues,
    setState,
    handleChange,
    handleSubmit
  ]);

  useEffect(() => {
    validateForm();
  }, [validateOnChange, validateForm]);

  return (
    <FormContext.Provider value={contextValue}>
      {Component
        ? (
          <Component data-compact={compact} className={classNames(Css.form, className)}>
            {children(contextValue)}
          </Component>
        )
        : children(contextValue)}
    </FormContext.Provider>
  );
});

export { default as FormElement } from "./lib/FormElement";
export { default as FormGroup } from "./lib/FormGroup";

export default React.memo(Form);
