import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React from "react";
import classNames from "classnames";

const BusinessStatus = ({ className, iconOnly, extraData, settings, onReconnectClick }) => {
  const {
    integrationService,
    lastEntitiesAutoSetAt,
    lastGptAiFineTuneStatus,
    uncategorizedRpaEnabled,
    reconciliationRpaEnabled,
    lastGptAiCategorizedAt,
    integrationServiceConnected
  } = extraData;

  const { uiTexts } = useSelector(getTextsData);

  const fineTuneError = lastGptAiFineTuneStatus === Constants.FINE_TUNE_STATUS_FAILED;

  const rpaMode = uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined;

  const rpaNotEnabled = uncategorizedRpaEnabled === false || reconciliationRpaEnabled === false;

  const settingUp = uncategorizedRpaEnabled === undefined && reconciliationRpaEnabled === undefined
    ? !lastEntitiesAutoSetAt
    : !lastGptAiCategorizedAt;

  if (!integrationService) return null;

  if (!integrationServiceConnected) {
    return (
      <div
        title={uiTexts.reconnect}
        className={classNames(Css.businessStatus, CommonCss.negativeText, className)}
        onClick={onReconnectClick}>
        <Icons.ArrowsClockwise />
        {!iconOnly && <span><u>{uiTexts.reconnect}</u></span>}
      </div>
    );
  }

  if (
    (settingUp && (fineTuneError || rpaNotEnabled)) || (rpaMode && (settings.rpaAutoAskClient === undefined))
  ) {
    return (
      <div
        title={uiTexts.actionRequired}
        className={classNames(Css.businessStatus, className)}>
        <Icons.WarningCircle className={CommonCss.warningText} />
        {!iconOnly && <span>{uiTexts.actionRequired}</span>}
      </div>
    );
  }

  if (settingUp) {
    return (
      <div
        title={uiTexts.settingUp}
        className={classNames(Css.businessStatus, className)}>
        <Icons.Spinner data-wait className={CommonCss.highlightText} />
        {!iconOnly && <span>{uiTexts.settingUp}</span>}
      </div>
    );
  }

  if (rpaNotEnabled) {
    return (
      <div
        title={uiTexts.roboticAiIsDisabled}
        className={classNames(Css.businessStatus, className)}>
        <Icons.Warning className={CommonCss.negativeText} />
        {!iconOnly && <span>{uiTexts.roboticAiIsDisabled}</span>}
      </div>
    );
  }

  if (rpaMode) {
    return (
      <div
        title={settings.rpaPaused ? uiTexts.roboticAiIsPaused : uiTexts.roboticAiBookkeeper}
        className={classNames(Css.businessStatus, className)}>
        <Icons.Cpu className={classNames(settings.rpaPaused && Css.warning)} />
        {!iconOnly && (
          <span className={CommonCss.neutralText}>
            {settings.rpaPaused ? uiTexts.roboticAiIsPaused : uiTexts.roboticAiBookkeeper}
          </span>
        )}
      </div>
    );
  }

  return !iconOnly && (
    <div
      title={uiTexts.dataEntryAutomationHub}
      className={classNames(Css.businessStatus, CommonCss.neutralText, className)}>
      {!iconOnly && uiTexts.dataEntryAutomationHub}
    </div>
  );
};

export default React.memo(BusinessStatus);
