import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import coloredLogo from "assets/coloredLogo.svg";

import { checkFetchingData } from "selectors/data";
import { getActiveOrganization, getProjectName } from "selectors/organizations";
import { useSelector } from "react-redux";
import BusinessSelector from "nlib/common/BusinessSelector";
import React, { useMemo } from "react";
import SettingsDropDown from "nlib/common/SettingsDropDown";
import classNames from "classnames";

const Header = () => {
  const fetchingData = useSelector(checkFetchingData);

  const activeOrganization = useSelector(getActiveOrganization);

  const projectName = useSelector(getProjectName);

  const { superAdminAccess } = activeOrganization || {};

  const { logoUrl } = activeOrganization.whiteLabel || {};

  const logoStyle = useMemo(() => {
    return { backgroundImage: `url(${logoUrl || coloredLogo})` };
  }, [logoUrl]);

  return (
    <div id="header" className={classNames(Css.header, superAdminAccess && Css.superAdminAccess)}>
      <div className={classNames(Css.container, CommonCss.mobileContainer)}>
        <div className={Css.logo} title={projectName} style={logoStyle}>
          <img src={logoUrl || coloredLogo} />
        </div>
        <div className={Css.businessSelectorWrap}>
          <BusinessSelector className={Css.businessSelector} />
        </div>
        <div className={Css.aside}>
          <div className={Css.item}>
            <SettingsDropDown mobile disabled={fetchingData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);
