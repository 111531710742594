import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React from "react";
import classNames from "classnames";

const DatePickerHeader = (props) => {
  const {
    label,
    showPrev = true,
    showNext = true,
    onLabelClick,
    onPrevClick,
    onNextClick
  } = props;

  return (
    <div className={Css.datePickerHeader}>
      {showPrev && (
        <div
          className={Css.prev}
          onClick={onPrevClick}>
          <Icons.CaretLeft />
        </div>
      )}
      <div
        className={classNames(Css.label, !!onLabelClick && Css.pointer)}
        onClick={onLabelClick}>
        {label}
      </div>
      {showNext && (
        <div
          className={Css.next}
          onClick={onNextClick}>
          <Icons.CaretRight />
        </div>
      )}
    </div>
  );
};

export default React.memo(DatePickerHeader);
