import { createSelector } from "reselect";
import { getActiveUsersData } from "selectors/organizations";
import { getCurrenciesData } from "selectors/metaData";

export const checkDocumentsFetching = ({ documents: { fetchingData } }) => fetchingData;

export const getUploadingDocumentsCount = ({ documents: { uploadingCount } }) => uploadingCount;

export const getDocumentsStats = ({ documents: { stats } }) => stats;

export const getLockedDocuments = ({ documents: { locked } }) => locked;

export const getDocumentsData = createSelector(
  [
    ({ documents: { data } }) => data,
    getCurrenciesData,
    getActiveUsersData
  ],
  (data, currencies, users) => {
    return data.map(({ currency, createdBy, updatedBy, sourceData, ...restData }) => {
      return {
        ...restData,
        sourceData,
        currency: currencies.some(({ code }) => code === currency) ? currency : null,
        createdBy: (createdBy && users.find(({ id }) => id === createdBy))
          || (sourceData && sourceData.from ? { email: sourceData.from } : null),
        updatedBy: users.find(({ id }) => id === updatedBy) || null
      };
    });
  }
);
