import { getOpenedModalsData } from "selectors/ui";
import { useDispatch, useSelector } from "react-redux";
import AddBusinessWindow from "nlib/windows/AddBusinessWindow";
import AddQuickBooksDesktopWindow from "nlib/windows/AddQuickBooksDesktopWindow";
import AskClientWindow from "nlib/windows/AskClientWindow";
import EditBusinessSettingsWindow from "nlib/windows/EditBusinessSettingsWindow";
import LinkContactWindow from "nlib/windows/LinkContactWindow";
import ModalNames from "const/ModalNames";
import PairTransactionsWindow from "nlib/windows/PairTransactionsWindow";
import React, { useCallback } from "react";
import SelectCategoryWindow from "nlib/windows/SelectCategoryWindow";
import SelectTaxRateWindow from "nlib/windows/SelectTaxRateWindow";
import SelectedBusinessesWindow from "nlib/windows/SelectedBusinessesWindow";
import SetupBackupWindow from "nlib/windows/SetupBackupWindow";
import UiActions from "actions/UiActions";
import UserDetailsWindow from "nlib/windows/UserDetailsWindow";

const {
  ADD_BUSINESS_WINDOW,
  ADD_QUICK_BOOKS_DESKTOP_WINDOW,
  ASK_CLIENT_WINDOW,
  EDIT_BUSINESS_SETTINGS_WINDOW,
  PAIR_TRANSACTIONS_WINDOW,
  SELECT_BUSINESSES_WINDOW,
  USER_DETAILS_WINDOW,
  SETUP_BACKUP_WINDOW,
  SELECT_CATEGORY_WINDOW,
  SELECT_TAX_RATE_WINDOW,
  LINK_CONTACT_WINDOW
} = ModalNames;

const getModalComponent = (modalName) => {
  switch (modalName) {
    case ADD_BUSINESS_WINDOW:
      return AddBusinessWindow;
    case ADD_QUICK_BOOKS_DESKTOP_WINDOW:
      return AddQuickBooksDesktopWindow;
    case ASK_CLIENT_WINDOW:
      return AskClientWindow;
    case EDIT_BUSINESS_SETTINGS_WINDOW:
      return EditBusinessSettingsWindow;
    case PAIR_TRANSACTIONS_WINDOW:
      return PairTransactionsWindow;
    case SELECT_BUSINESSES_WINDOW:
      return SelectedBusinessesWindow;
    case USER_DETAILS_WINDOW:
      return UserDetailsWindow;
    case SETUP_BACKUP_WINDOW:
      return SetupBackupWindow;
    case SELECT_CATEGORY_WINDOW:
      return SelectCategoryWindow;
    case SELECT_TAX_RATE_WINDOW:
      return SelectTaxRateWindow;
    case LINK_CONTACT_WINDOW:
      return LinkContactWindow;

    default:
      return null;
  }
};

const ModalsContainer = () => {
  const dispatch = useDispatch();

  const openedModalsData = useSelector(getOpenedModalsData);

  const handleModalClose = useCallback((result) => {
    dispatch(UiActions.hideCustomModal(result));
  }, [dispatch]);

  return openedModalsData.map(({ modalName, ...restData }, index) => {
    const ModalComponent = getModalComponent(modalName);

    return ModalComponent
      ? <ModalComponent
        key={modalName}
        disabled={(index + 1) < openedModalsData.length}
        noOverlay={!!index}
        {...restData}
        onClose={handleModalClose} />
      : null;
  });
};

export default React.memo(ModalsContainer);
