import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Modal } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";
import SelectContactInput from "nlib/common/SelectContactInput";

const LinkContactWindow = ({ onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const [{ address, vendorId }, setState] = useState({ address: {}, vendorId: null });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleContactInputChange = useCallback((value) => {
    setState({ address: value, vendorId: value.id });
  }, []);

  const handleConfirmClick = useCallback(() => {
    onClose({ address, vendorId });
  }, [address, vendorId, onClose]);

  const handleCancelClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      dialogClassName={Css.linkContactWindow}
      title={uiTexts.selectContact}
      iconComponent={Icons.User}
      onClose={handleClose}>
      <SelectContactInput
        useWidget
        value={address}
        invalid={!address.id}
        valid={address.id}
        onChange={handleContactInputChange} />
      <ModalFooter>
        <Button
          primary large
          disabled={!address.id}
          onClick={handleConfirmClick}>
          {uiTexts.save}
        </Button>
        <Button large onClick={handleCancelClick}>
          {uiTexts.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(LinkContactWindow);
