import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Input, Modal } from "nlib/ui";
import { ModalFooter } from "nlib/ui/Modal";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Radio from "nlib/ui/Radio";
import RadioGroup from "nlib/ui/RadioGroup";
import React, { useCallback, useEffect, useRef, useState } from "react";

const VARIANTS = {
  SOCIAL_MEDIA: "Social media",
  YOUTUBE: "Youtube",
  GOOGLE: "Google",
  BLOG_OR_PUBLICATION: "Blog or publication",
  WORD_OF_MOUTH: "Word-of-mouth",
  OTHER: "Other"
};

const DISCOVERY_RESOURCE_MAX_LENGTH = 255;

const AskDiscoverySourceModal = ({ onClose }) => {
  const inputRef = useRef();

  const { uiTexts, messages } = useSelector(getTextsData);

  const [
    modalTitleText,
    modalMainText,
    socialMediaLabel,
    youTubeLabel,
    googleLabel,
    blogOrPublicationLabel,
    wordOfMouthLabel,
    otherLabel
  ] = messages.askDiscoverySource;

  const [variant, setVariant] = useState(null);

  const [customVariantText, setCustomVariantText] = useState("");

  const handleVariantChange = useCallback((value) => {
    setVariant(value);
  }, []);

  const handleCustomVariantInputChange = useCallback((value) => {
    setCustomVariantText(value);
  }, []);

  const handleCustomVariantInputFocus = useCallback(() => {
    setVariant(VARIANTS.OTHER);
  }, []);

  const handleSkipButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmitButtonClick = useCallback(() => {
    onClose(variant === VARIANTS.OTHER
      ? `${VARIANTS.OTHER}: ${customVariantText.substring(0, DISCOVERY_RESOURCE_MAX_LENGTH)}`
      : variant);
  }, [customVariantText, variant, onClose]);

  useEffect(() => {
    if (variant === VARIANTS.OTHER) {
      inputRef.current.focus();
    }
  }, [variant]);

  return (
    <Modal
      iconComponent={Icons.Question}
      title={modalTitleText}
      dialogClassName={Css.askDiscoverySourceModal}
      onClose={onClose}>
      <div className={Css.content}>
        <div className={Css.text}>{modalMainText}</div>
        <div className={Css.variants}>
          <RadioGroup
            name="variant"
            checked={variant}
            onChange={handleVariantChange}>
            <Radio value={VARIANTS.GOOGLE}>
              {googleLabel}
            </Radio>
            <Radio value={VARIANTS.BLOG_OR_PUBLICATION}>
              {blogOrPublicationLabel}
            </Radio>
            <Radio value={VARIANTS.YOUTUBE}>
              {youTubeLabel}
            </Radio>
            <Radio value={VARIANTS.WORD_OF_MOUTH}>
              {wordOfMouthLabel}
            </Radio>
            <Radio value={VARIANTS.SOCIAL_MEDIA}>
              {socialMediaLabel}
            </Radio>
            <Radio value={VARIANTS.OTHER}>
              {otherLabel}
            </Radio>
          </RadioGroup>
        </div>
        {(variant === VARIANTS.OTHER) && (
          <Input
            inputRef={inputRef}
            placeholder={uiTexts.enterYourOption}
            className={Css.input}
            value={customVariantText}
            maxLength={DISCOVERY_RESOURCE_MAX_LENGTH}
            onFocus={handleCustomVariantInputFocus}
            onChange={handleCustomVariantInputChange} />
        )}
      </div>
      <ModalFooter>
        <Button large onClick={handleSkipButtonClick}>{uiTexts.skip}</Button>
        <Button
          primary large
          disabled={!variant || (variant === VARIANTS.OTHER && !customVariantText.trim())}
          onClick={handleSubmitButtonClick}>
          {uiTexts.submit}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(AskDiscoverySourceModal);
