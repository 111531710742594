import Css from "./style.module.scss";

import defaultBusinessLogo from "assets/business.svg";

import * as Icons from "@phosphor-icons/react";
import { Input } from "nlib/ui";
import { checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization, getProjectName } from "selectors/organizations";
import { getSelectedBusinessId, getUserBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BusinessStatus from "../BusinessStatus";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import Pages from "nlib/pages/Pages";
import React, { useCallback, useMemo, useState } from "react";
import Select from "nlib/ui/Select";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";

const { STATUSES: { NEED_REACTION, TO_REVIEW } } = DataConstants;

const ADD_NEW_BUSINESS_ID = "+";

const MIN_BUSINESSES_COUNT_FOR_SEARCH = 10;

const getStatus = (route) => {
  switch (route) {
    case UiRoutes.TRANSACTIONS:
      return NEED_REACTION;

    case UiRoutes.DOCUMENTS:
      return TO_REVIEW;

    default:
      return undefined;
  }
};

const BusinessSelector = ({ disabled, className }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { pathname } = useLocation();

  const projectName = useSelector(getProjectName);

  const businessesData = useSelector(getUserBusinessesData);

  const businessUser = useSelector(checkIsBusinessUser);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const { countryCode } = useSelector(getActiveOrganization);

  const { uiTexts } = useSelector(getTextsData);

  const czCountry = countryCode === Countries.CZ;

  const [searchString, setSearchString] = useState("");

  const handleSearchChange = useCallback((value) => {
    setSearchString(value);
  }, []);

  const options = useMemo(() => {
    const searchStringNormalized = searchString.trim().toLowerCase();

    const mainOptions = businessesData.map(({ id, name, extraData = {}, settings = {} }) => {
      const defaultBusiness = { logo: defaultBusinessLogo, label: projectName };

      const found = !czCountry && IntegrationServices.getByValue(extraData.integrationService);

      const { logo: serviceLogo, label: serviceName } = found || defaultBusiness;

      return {
        value: id,
        hidden: searchStringNormalized && !`${name}`.toLowerCase().includes(searchStringNormalized),
        label: (
          <div className={Css.listItem} title={name}>
            <div className={Css.logo}>
              <img title={serviceName} alt={serviceName} src={serviceLogo} />
            </div>
            {!businessUser && (
              <BusinessStatus
                iconOnly
                className={Css.businessStatus}
                extraData={extraData}
                settings={settings} />
            )}
            <div className={Css.name}>{name}</div>
          </div>
        )
      };
    });

    return [
      !businessUser && !searchStringNormalized && !Utils.checkIsTouchDevice() && {
        value: ADD_NEW_BUSINESS_ID,
        label: (
          <div className={Css.addNew}>
            <Icons.Plus className={Css.plus} weight="bold" />
            <span>{uiTexts.addNewBusiness}</span>
          </div>
        )
      },
      ...mainOptions,
      searchStringNormalized && mainOptions.every(({ hidden }) => hidden) && {
        value: null,
        disabled: true,
        label: <span>{uiTexts.noResultsFound}</span>
      }
    ].filter(Boolean);
  }, [searchString, businessesData, businessUser, uiTexts, projectName, czCountry]);

  const handleChange = useCallback((businessId) => {
    if (businessId === ADD_NEW_BUSINESS_ID) {
      dispatch(UiActions.showAddBusinessWindow());
    } else {
      const { route } = Pages.getCurrentRouteInfo(pathname);

      const businessData = Utils.arrayFindById(businessesData, businessId);

      const rpaMode = businessData?.extraData?.uncategorizedRpaEnabled !== undefined;

      if (rpaMode && route === UiRoutes.TRANSACTIONS) {
        history.push(`/${businessId}${route}`);
      } else {
        const status = getStatus(route);

        history.push(`/${businessId}${route || ""}${status ? `?status=${status}` : ""}`);
      }
    }
  }, [pathname, businessesData, dispatch, history]);

  const handleSelectToggle = useCallback(() => {
    setSearchString("");
  }, []);

  if (!businessesData.length) return null;

  return (
    <Select
      disableSelected
      centered={false}
      disabled={disabled}
      className={classNames(Css.businessSelector, className)}
      dropDownClassName={Css.businessSelectorDropDown}
      placeholder={uiTexts.selectBusinesses}
      value={selectedBusinessId}
      options={options}
      prepend={(businessesData.length >= MIN_BUSINESSES_COUNT_FOR_SEARCH) && (
        <Input
          autoFocus
          className={Css.businessSearch}
          placeholder={uiTexts.searchBusiness}
          value={searchString}
          onChange={handleSearchChange} />
      )}
      onToggle={handleSelectToggle}
      onChange={handleChange} />
  );
};

export default React.memo(BusinessSelector);
