
import DatePicker from "nlib/ui/DatePicker";
import React, { useMemo } from "react";
import Utils from "utils/Utils";
import moment from "moment";

const DatePickerLocalized = (props) => {
  const translations = useMemo(() => {
    return {
      months: moment.months(),
      days: moment.weekdaysShort()
    };
  }, []);

  return (
    <DatePicker modal={Utils.checkIsTouchDevice()} translations={translations} {...props} />
  );
};

export default React.memo(DatePickerLocalized);
