import Css from "./style.module.scss";

import { Checkbox, Input } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useMainApi } from "hooks";
import { useSelector } from "react-redux";
import FormContext from "contexts/FormContext";
import IntegrationServices from "const/IntegrationServices";
import MultiSelect from "nlib/ui/MultiSelect";
import React, { useCallback, useContext, useMemo } from "react";
import UiRoutes from "const/UiRoutes";

const SelectExcludedAccounts = ({ value, disabled, businessId, integrationService, onChange, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = integrationService === IntegrationServices.QUICK_BOOKS.value;

  const [{ results: accountsData }, fetching] = useMainApi({
    initialData: { results: [] },
    parameters: [`${UiRoutes.BUSINESSES}/${businessId}${UiRoutes.ACCOUNTS}`]
  });

  const { values: { rpaExcludedAccountsToggle }, onChange: onFormChange } = useContext(FormContext);

  const accountsNames = useMemo(() => {
    return accountsData
      .filter(({ extraData: { paymentAccount } = {} }) => !quickBooksBusiness || paymentAccount)
      .map(({ name }) => ({ value: name, label: name }));
  }, [quickBooksBusiness, accountsData]);

  const handleRpaPausedToggleChange = useCallback(() => {
    onFormChange({ name: "rpaExcludedAccountsToggle", value: !rpaExcludedAccountsToggle });
    if (!rpaExcludedAccountsToggle) onChange([]);
  }, [rpaExcludedAccountsToggle, onChange, onFormChange]);

  return (
    <div className={Css.selectRpaFrequency}>
      {rpaExcludedAccountsToggle
        ? <Input
          {...restProps}
          disabled
          className={Css.input}
          value={uiTexts.noAccountsExcluded} />
        : <MultiSelect
          value={value}
          disabled={disabled || fetching}
          className={Css.input}
          invalid={!value.length}
          options={accountsNames}
          onChange={onChange}
          placeholder={fetching ? `${uiTexts.loading}...` : uiTexts.enterAccountName}
          {...restProps} />}
      <Checkbox
        toggle
        data-tooltip={rpaExcludedAccountsToggle ? uiTexts.turnOn : uiTexts.turnOff}
        value={!rpaExcludedAccountsToggle}
        onClick={handleRpaPausedToggleChange} />
    </div>
  );
};

export default React.memo(SelectExcludedAccounts);
