import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getAccountsData } from "selectors/accounts";
import { getCurrentQuickBooksRealmId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DebounceInput from "nlib/ui/DebounceInput";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Row from "nlib/ui/Row";
import Select from "nlib/ui/Select";
import classNames from "classnames";

const Filters = ({ className, disabled, filters, onChange }) => {
  const { uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const accountsData = useSelector(getAccountsData);

  const { text = "", accountId, type } = filters;

  const [textInputValue, setTextInputValue] = useState(text);

  const accountOptions = useMemo(() => [
    accountId && { label: uiTexts.all },
    ...accountsData
      .filter(({ extraData: { paymentAccount } = {} }) => !quickBooksBusiness || paymentAccount)
      .map(({ id, name, accountNumber }) => ({
        value: id,
        label: name || accountNumber
      }))
  ], [accountId, accountsData, quickBooksBusiness, uiTexts.all]);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    { value: DataConstants.TRANSACTION_TYPES.WITHDRAW, label: uiTexts.expenses },
    { value: DataConstants.TRANSACTION_TYPES.DEPOSIT, label: uiTexts.income }
  ], [type, uiTexts]);

  const handleTextInputComplete = useCallback((value) => {
    onChange({ text: value || null });
  }, [onChange]);

  const handleAccountChange = useCallback((value) => {
    onChange({ accountId: value });
  }, [onChange]);

  const handleTypeChange = useCallback((value) => {
    onChange({ type: value });
  }, [onChange]);

  useEffect(() => {
    setTextInputValue(text || "");
  }, [text]);

  return (
    <div className={classNames(Css.filters, className)}>
      <Row>
        <DebounceInput
          cleanable
          disabled={disabled}
          active={!!text}
          className={Css.search}
          placeholder={uiTexts.searchTransactions}
          value={textInputValue}
          onChange={setTextInputValue}
          onInputComplete={handleTextInputComplete}
          iconBefore={Icons.MagnifyingGlass} />

        <Select
          disabled={disabled}
          active={!!accountId}
          value={accountId}
          className={Css.account}
          placeholder={uiTexts.selectAccount}
          iconBefore={Icons.Bank}
          options={accountOptions}
          onChange={handleAccountChange} />

        <Select
          disabled={disabled}
          active={!!type}
          value={type}
          className={Css.type}
          placeholder={uiTexts.selectType}
          iconBefore={Icons.CurrencyCircleDollar}
          options={typeOptions}
          onChange={handleTypeChange} />
      </Row>
    </div>
  );
};

export default React.memo(Filters);
